import { Fragment, useEffect, useState } from 'react';
import {
  AutocompleteCustomizado,
  CampoDeDataBase,
  CampoDeHoraBase,
  CampoDeTextoBase,
  ContainerData,
  FormularioCustomizado,
  FormularioCustomizadoMultiSelect,
  NumericFormatCustomizado,
  TextFieldMonetario,
  NumericFormatInput,
  NumberFormatBaseInput,
  FormularioCustomizadoSelecao,
  CampoDeTextoBaseSelecionarCliente,
  CampoDeTextoBaseAutoComplete,
  CampoDeTextoCustom,
  InputPorcentagemWayservice,
  CampoCorBase,
  CampoDeDataSemHoraBase,
  InputCustomizado,
  CampoDeInputNumericoAlternativoBase,
  CustomTooltip,
} from "./styles";
import {
  Box,
  CircularProgress,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  Search,
  SearchOff,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import Chip from "../Chip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faQuestion,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import useTamanhoTela from "../../utils/useTamanhoTela";
import { renderTimeViewClock } from "@mui/x-date-pickers";
import { useTheme } from "@emotion/react";
import { toast } from "react-toastify";
import { IconeBotao } from "../BottomSheetAtalhos/styles";
import { Question, WarningCircle } from "phosphor-react";

export const CampoDeTexto = ({
  titulo,
  fundoTransparente = false,
  style,
  value,
  tabindex,
  refs,
  typeNumber = false,
  ocultarSetas = false,
  focusProximo,
  onBlur,
  disabled = false,
  variant = 'standard',
  onKeyDown = () => { },
  className = '',
  type = 'text',
  maxlength = 999,
  register,
  popup = false,
  mensagemPopup = "",
  error = false,
  ...rest
}) => {
  return popup ? (
    <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
      <CampoDeTextoBase
        className={className}
        typeNumber={typeNumber}
        label={titulo}
        error={error}
        $fundoTransparente={fundoTransparente}
        variant={variant}
        style={{
          ...style,
          opacity: disabled ? 0.7 : 1,
          cursor: disabled ? "not-allowed" : "default",
        }}
        value={value}
        onBlur={focusProximo || onBlur}
        inputProps={{ tabIndex: tabindex }}
        InputProps={{
          endAdornment: error && <WarningCircle size={22} color="#f44336" />,
        }}
        tabIndex={tabindex}
        ref={refs}
        ocultarSetas={ocultarSetas}
        onKeyDown={onKeyDown}
        type={type}
        maxlength={maxlength}
        disabled={disabled}
        placeholder="Digite aqui"
        {...register}
        {...rest}
      />

      <CustomTooltip title={mensagemPopup} placement="bottom" arrow>
        <Question color="rgba(0, 100, 149, 1)" size={20} weight="bold" />
      </CustomTooltip>
    </div>
  ) : (
    <CampoDeTextoBase
      className={className}
      typeNumber={typeNumber}
      label={titulo}
      error={error}
      $fundoTransparente={fundoTransparente}
      variant={variant}
      style={{
        ...style,
        opacity: disabled ? 0.7 : 1,
        cursor: disabled ? 'not-allowed' : 'default',
      }}
      value={value}
      onBlur={focusProximo || onBlur}
      inputProps={{ tabIndex: tabindex }}
      InputProps={{
        endAdornment: error && <WarningCircle size={22} color="#f44336" />,
      }}
      tabIndex={tabindex}
      ref={refs}
      ocultarSetas={ocultarSetas}
      onKeyDown={onKeyDown}
      type={type}
      maxlength={maxlength}
      disabled={disabled}
      placeholder="Digite aqui"
      {...register}
      {...rest}
    />
  );
};

export const CampoDeTextoSelecionarCliente = ({
  titulo,
  fundoTransparente = false,
  style,
  value,
  error = false,
  disabled = false,
  ...rest
}) => {
  return (
    <CampoDeTextoBaseSelecionarCliente
      {...rest}
      label={titulo}
      variant="standard"
      style={style}
      value={value == undefined ? "" : value}
      error={error}
      className={disabled && "not-allowed"}
      disabled={disabled}
    />
  );
};

export const CampoDeSenha = ({ titulo, variant = 'standard', ...rest }) => {
  const [mostrarSenha, setMostrarSenha] = useState(false);

  function controlarMostrarSenha() {
    setMostrarSenha(!mostrarSenha);
  }

  return (
    <FormularioCustomizado variant="standard" value={rest.value}>
      <InputCustomizado htmlFor="standard-adornment-password">
        {titulo}
      </InputCustomizado>
      <Input
        {...rest}
        variant={'filled'}
        id="standard-adornment-password"
        type={mostrarSenha ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={controlarMostrarSenha}
              onMouseDown={controlarMostrarSenha}
              style={{ position: 'relative', bottom: '0px' }}
            >
              {mostrarSenha ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormularioCustomizado>
  );
};

export const CampoDeData = ({
  titulo,
  style,
  error,
  refs,
  limparCampo,
  mostrarHora = true,
  onBlurTextField = () => { },
  ...rest
}) => {
  return (
    <CampoDeDataBase
      {...rest}
      ref={refs}
      label={titulo}
      variant="standart"
      dayOfWeekFormatter={(day) => day.charAt(0).toUpperCase()}
      viewRenderers={
        mostrarHora
          ? null
          : {
            hours: null,
            minutes: null,
          }
      }
      views={mostrarHora ? null : ['year', 'month', 'day']}
      slotProps={{
        textField: { variant: "standard", error: error },
        ...(limparCampo
          ? {
            field: {
              clearable: true,
              onClear: limparCampo,
            },
          }
          : {}),
      }}
    />
  );
};

export const CampoDeSelecao = ({
  titulo,
  referenciaNome,
  referenciaValor,
  defaultValue,
  valor,
  disabled,
  onChange,
  items = [],
  campoVazio = true,
  iconeSearch = false,
  valorPadrao,
  onOpen,
  multiple = false,
  ...rest
}) => {
  const theme = useTheme();
  // Modelo de items:
  // Lista de Strings: ['item1', 'item2', 'item3']
  // Lista de Objetos: [
  //   {titulo: 'item1', valor: 'valor1'},
  //   {titulo: 'item2', valor: 'valor2'},
  //   {titulo: 'item3', valor: 'valor3'},
  // ]

  function GerenciarObjetosOuTextos() {
    return items.map((item, index) => {
      if (typeof item === 'string') {
        return (
          <MenuItem key={titulo + item + index} value={item}>
            {item}
          </MenuItem>
        );
      } else if (typeof item === 'object') {
        return (
          <MenuItem
            style={item["esconderMenuItem"] ? { display: "none" } : {}}
            key={
              titulo + item[referenciaValor ? referenciaValor : 'valor'] + index
            }
            value={item[referenciaValor ? referenciaValor : 'valor']}
          >
            {item[referenciaNome ? referenciaNome : 'titulo']}
          </MenuItem>
        );
      }
    });
  }
  return (
    <FormularioCustomizadoSelecao
      variant="standard"
      {...rest}
      value={valor}
      disabled={disabled}
    >
      <InputLabel
        style={{
          display: iconeSearch ? 'flex' : '',
          alignItems: iconeSearch ? 'center' : '',
          gap: iconeSearch ? 10 : '',
          paddingLeft: iconeSearch ? 16 : '',
        }}
        defaultValue={"Atacado"}
      >
        {iconeSearch && (
          <FontAwesomeIcon
            icon={faSearch}
            style={{
              color: theme.adaptativo('neutral50', 'neutral100'),
              width: 15,
              height: 15,
              weight: 'bold',
            }}
          />
        )}
        {titulo}
      </InputLabel>
      <Select
        onOpen={onOpen}
        value={items.length > 0 && valor ? valor : multiple ? [] : ""}
        multiple={multiple}
        label={titulo}
        onChange={onChange}
        defaultValue={defaultValue}
      >
        {campoVazio && <MenuItem value={'Selecione'}>Selecione</MenuItem>};
        {GerenciarObjetosOuTextos()}
      </Select>
    </FormularioCustomizadoSelecao>
  );
};

export default function CampoDeSelecaoChips({
  titulo,
  itemsSelecionados,
  setItemsSelecionados,
  style,
  items = [],
  error,
  disabled,
  focusBtnSalvar,
}) {
  const mudarValores = (event) => {
    const {
      target: { value },
    } = event;

    if (value.includes('Selecionar todos')) {
      setItemsSelecionados(items);

      //Fechar opções do select ao selecionar todas
      document.querySelector('.MuiBackdrop-invisible').click();

      return;
    }

    //Ele pega o Valor do evento
    //Se o valor for uma string, ele separa por virgula e o transforma em um array
    //Se não, ele pega o valor e retorna o valor unico
    setItemsSelecionados(typeof value === 'string' ? value.split(',') : value);
  };

  const deletarItem = (valor) => {
    setItemsSelecionados([
      ...itemsSelecionados.filter((item) => item !== valor),
    ]);
  };

  const selecionarTodos = (e) => {
    e.stopPropagation();

    setItemsSelecionados(...items);
  };

  const onKeyUpCapture = (e) => {
    if (e.key === 'Tab') {
      if (typeof focusBtnSalvar === 'function') {
        focusBtnSalvar();
      }
      e.preventDefault();
      return false;
    }
  };

  return (
    <FormularioCustomizadoMultiSelect
      variant="standard"
      style={style}
      disabled={disabled}
    >
      <InputLabel>{titulo}</InputLabel>
      <Select
        error={error}
        value={itemsSelecionados ? itemsSelecionados : []}
        label={titulo}
        onChange={mudarValores}
        onKeyUpCapture={onKeyUpCapture}
        multiple
        // input={<OutlinedInput label="Chip" />}
        renderValue={(selected) => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
              height: '27px',
            }}
          >
            {selected.map((value) => (
              <Chip
                label={value}
                color="primary"
                variant="outlined"
                onDelete={() => deletarItem(value)}
                onMouseDown={(event) => event.stopPropagation()}
                key={value + 'chipSelect'}
              />
            ))}
          </Box>
        )}
      // MenuProps={MenuProps}
      >
        <MenuItem value="Selecionar todos">Selecionar todos</MenuItem>
        {items.map((item) => (
          <MenuItem
            key={item}
            value={item}
          // style={getStyles(name, personName)}
          >
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormularioCustomizadoMultiSelect>
  );
}

export function CampoDeInputMonetario({
  style = {},
  onChange = () => { },
  value,
  label,
  titulo,
  error,
  onBlur = () => { },
  valorMax = 9999999.99,
  tabindex,
  pedidoCompra = false,
  refs,
  classess = "",
  disabled = false,
  customId = ""
}) {
  const [valorInicialSetado, setValorInicialSetado] = useState(false);
  // Aviso
  // Diferente dos outros campos de Input, por fazer o tratamento, este retorna diretamente o Valor, e não o
  // e.target.value como os outros.

  const propriedadesMaterialUIStandard = {
    id: "standard-basic",
    variant: "standard",
    placeholder: "R$ 0,00",
    InputProps: {
      InputAdornment: <InputAdornment position="start">R$</InputAdornment>,
      placeholder: "R$ 0,00",
    },
  };

  const [representacaoVisual, setRepresentacaoVisual] = useState("");

  useEffect(() => {
    if (value == "") {
      setRepresentacaoVisual("");
    }

    console.log(value, "teste value")
  }, [value]);

  useEffect(() => {
    if (!valorInicialSetado) {
      if (value != undefined) {
        setValorInicialSetado(true);
        let valor = value;
        valor = tratarLimiteValor(valor);
        setRepresentacaoVisual(
          valor.toLocaleString("pt-br", { style: "currency", currency: "BRL" })
        );
      }
    }
  });

  function onChangeInterno(valorAtualizado) {
    let valor = parseFloat(
      valorAtualizado.target.value
        .replace("R$", "")
        .replaceAll(".", "")
        .replaceAll(",", ".")
    );

    valor = tratarLimiteValor(valor);

    onChange(valor);

    setRepresentacaoVisual(valorAtualizado.target.value);
  }

  function tratarLimiteValor(valor) {
    let novoValor = valor;
    novoValor = tratarValorMax(novoValor);
    return novoValor;
  }

  function tratarValorMax(valor) {
    if (valorMax || pedidoCompra) {
      const valorMaxAtualizado = pedidoCompra ? 9999.99 : valorMax;
      let max = valorMaxAtualizado * 100;
      if (valor > max) {
        return max;
      }
    }

    return valor;
  }

  const formatCurrencyByEnd = (value) => {
    if (!Number(value)) return "";

    let valor = tratarLimiteValor(value);

    const amount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    }).format(parseFloat(valor) / 100);

    return `${amount}`;
  };

  return (
    <NumberFormatBaseInput
      className={`input-CampoDeInputMonetario ${classess}`}
      id={customId}
      // {...rest}
      format={formatCurrencyByEnd}
      style={{
        ...style,
        opacity: disabled ? 0.7 : 1,
        cursor: disabled ? "not-allowed" : "default",
      }}
      label={label || titulo}
      //onValueChange={onChangeInterno}
      onChange={onChangeInterno}
      onBlur={onBlur}
      inputRef={refs}
      value={representacaoVisual}
      thousandSeparator="."
      decimalSeparator=","
      prefix="R$ "
      decimalScale={2}
      fixedDecimalScale
      allowEmptyFormatting={false}
      error={error}
      customInput={TextFieldMonetario}
      disabled={disabled}
      {...propriedadesMaterialUIStandard}
      InputProps={{ tabIndex: tabindex }}
    />
  );
}

export function CampoDeInputMonetarioPermiteZero({
  style = {},
  onChange = () => { },
  value,
  label,
  titulo,
  error,
  onBlur = () => { },
  disabled,
  permitirZero = false,
  valorMax = 9999999999.99,
  classess = "",
  tabindex,
  refs,
}) {
  const [valorInicialSetado, setValorInicialSetado] = useState(false);
  // Aviso
  // Diferente dos outros campos de Input, por fazer o tratamento, este retorna diretamente o Valor, e não o
  // e.target.value como os outros.

  const propriedadesMaterialUIStandard = {
    id: "standard-basic",
    variant: "standard",
    placeholder: "R$ 0,00",
    InputProps: {
      InputAdornment: <InputAdornment position="start">R$</InputAdornment>,
      placeholder: "R$ 0,00",
    },
  };

  const [representacaoVisual, setRepresentacaoVisual] = useState("");
  useEffect(() => {
    if (value == "" || String(value) == "NaN" || value == "0") {
      setRepresentacaoVisual("0");
    } else if (value) {
      setRepresentacaoVisual(
        permitirZero
          ? value?.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
          : ""
      );
    }
  }, [value]);
  useEffect(() => {
    if (!valorInicialSetado) {
      if (value != undefined) {
        setValorInicialSetado(true);
        let valor = value;
        valor = tratarLimiteValor(valor);
        setRepresentacaoVisual(
          valor.toLocaleString("pt-br", { style: "currency", currency: "BRL" })
        );
      }
    }
  });

  function onChangeInterno(valorAtualizado) {
    let valor = parseFloat(
      valorAtualizado.target.value
        .replace("R$", "")
        .replaceAll(".", "")
        .replaceAll(",", ".")
    );

    valor = tratarLimiteValor(valor);

    onChange(valor);
    setRepresentacaoVisual(valorAtualizado.target.value);
  }

  function tratarLimiteValor(valor) {
    let novoValor = valor;
    novoValor = tratarValorMax(novoValor);
    return novoValor;
  }

  function tratarValorMax(valor) {
    if (valorMax) {
      let max = valorMax * 100;
      if (valor > max) {
        return max;
      }
    }

    return valor;
  }

  const formatCurrencyByEnd = (value) => {
    if (!Number(value) && !permitirZero) return "";

    let valor = tratarLimiteValor(value);

    const amount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    }).format(parseFloat(valor) / 100);

    return value ? `${amount}` : "R$ 0,00";
  };

  return (
    <NumberFormatBaseInput
      className={`input-CampoDeInputMonetario ${classess}`}
      // {...rest}
      format={formatCurrencyByEnd}
      style={{
        ...style,
        opacity: disabled ? 0.7 : 1,
        cursor: disabled ? "not-allowed" : "default",
      }}
      label={label || titulo}
      //onValueChange={onChangeInterno}
      onChange={onChangeInterno}
      onBlur={onBlur}
      value={representacaoVisual}
      thousandSeparator="."
      decimalSeparator=","
      prefix="R$ "
      decimalScale={2}
      fixedDecimalScale
      allowEmptyFormatting={false}
      error={error}
      customInput={TextFieldMonetario}
      disabled={disabled}
      {...propriedadesMaterialUIStandard}
      ref={refs}
      InputProps={{ tabIndex: tabindex }}
    />
  );
}

export function CampoDeInputNumerico({
  style = {},
  onChange,
  value,
  label,
  error,
  onBlur = () => { },
  disabled,
  tabindex,
  permitirZero = false,
  placeholder,
  permitirZeroAEsquerda = false,
  defaultValue,
  allowNegative = false,
  maxValue,
  classess = "",
}) {
  // Aviso
  // Diferente dos outros campos de Input, por fazer o tratamento, este retorna diretamente o Valor, e não o
  // e.target.value como os outros.
  const propriedadesMaterialUIStandard = {
    id: 'standard-basic',
    variant: 'standard',
    InputProps: {
      placeholder: placeholder,
    },
  };

  const [representacaoVisual, setRepresentacaoVisual] = useState(
    typeof defaultValue ? defaultValue : ""
  );

  useEffect(() => {
    if (value) {
      setRepresentacaoVisual(value);
    } else {
      setRepresentacaoVisual(permitirZero ? value : '');
    }
  }, [value]);

  function onChangeInterno(valorAtualizado) {
    let valor = valorAtualizado.target.value;

    if (allowNegative) {
      onChange(valor.replace("+", "").replaceAll(",", "").replaceAll(".", ""));
    } else if (permitirZeroAEsquerda) {
      onChange(
        valor
          .replace('+', '')
          .replaceAll(',', '')
          .replaceAll('.', '')
      );
    } else if (permitirZeroAEsquerda) {
      onChange(
        valor
          .replace('+', '')
          .replaceAll('-', '')
          .replaceAll(',', '')
          .replaceAll('.', '')
      );
    } else {
      onChange(
        parseFloat(
          valor
            .replace('+', '')
            .replaceAll('-', '')
            .replaceAll(',', '')
            .replaceAll('.', '')
        )
      );
    }
    setRepresentacaoVisual(valor);
  }

  return (
    <NumericFormatInput
      className={classess}
      style={{ ...style }}
      label={label}
      onChange={onChangeInterno}
      onBlur={onBlur}
      value={representacaoVisual}
      allowNegative={allowNegative}
      allowLeadingZeros={permitirZeroAEsquerda}
      min={1}
      error={error}
      customInput={TextFieldMonetario}
      disabled={disabled}
      InputProps={{ tabIndex: tabindex }}
      {...propriedadesMaterialUIStandard}
    />
  );
}

export function CampoAutoComplete({
  titulo,
  style,
  opcoes,
  opcaoUnica = false,
  disableClearable = false,
  error = false,
  onChange,
  value,
  referenciaKey,
  finalizadora = false,
  referenciaNome,
  opcaoTodos = false,
  tabindex,
  refs,
  focusProximo,
  isOptionEqualToValue,
  disabled,
}) {
  const [texto, setTexto] = useState("");

  //Informe o opcaoTodos como true para criar uma opção onde pode selecionar ou retirar a seleção de todos os itens
  if (opcaoTodos) {
    const opcaoJaExiste = (obj) => obj.id === "todos";

    if (opcoes == undefined) {
      opcoes = [];
    }

    if (!opcoes.some(opcaoJaExiste)) {
      opcoes.unshift({ id: "todos", descricao: "Selecionar todos", tipo: "Selecionar todos", displayValue: "Selecionar todos" });
    }

    if (opcoes.length - 1 == value.length) {
      opcoes = opcoes.filter((item) => item.id != "todos");
      opcoes.unshift({ id: "todos", descricao: "Retirar todos", tipo: "Retirar todos", displayValue: "Retirar todos" });
    }
  }

  const selecionarItem = (item, valor) => {
    try {
      const opcaoJaExiste = (obj) => obj.id === "todos";

      if (valor.some(opcaoJaExiste)) {
        //Verificar se todos já foram selecionados e adicionar a opção Retirar todos
        if (opcoes.length - 1 == value.length) {
          onChange(item, []);
        } else {
          onChange(
            item,
            opcoes.filter((item) => item.id != "todos")
          );
        }
      } else {
        onChange(
          item,
          valor.filter((item) => item.id != "todos")
        );
      }
    } catch (error) { }
  };

  return (
    <AutocompleteCustomizado
      disabled={disabled}
      noOptionsText={"Nenhum registro cadastrado"}
      isOptionEqualToValue={isOptionEqualToValue}
      multiple={!opcaoUnica}
      disableClearable={disableClearable}
      sx={{ ...style, width: "100%" }}
      getOptionLabel={(option) => finalizadora ? option.finalizadoraDTO[referenciaNome] : option[referenciaNome]}
      options={opcoes}
      onChange={opcaoUnica ? onChange : selecionarItem}
      value={value}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option[referenciaKey] + "autocomplete"}>
            {finalizadora ? option.finalizadoraDTO[referenciaNome] : option[referenciaNome]}
          </li>
        );
      }}
      renderInput={(params) => (
        <CampoDeTextoBaseAutoComplete
          disabled={disabled}
          ref={refs}
          tabIndex={tabindex}
          onBlur={focusProximo}
          {...params}
          error={error}
          label={titulo}
          variant="standard"
          value={value}
          onChange={(e) => setTexto(e.target.value)}
        />
      )}
    />
  );
}


export function CampoAutoCompleteAssincrono({
  titulo,
  funcaoGetter = () => { },
  style,
  error = false,
}) {
  const [texto, setTexto] = useState('');
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        let items = await funcaoGetter(texto);
        if (items && items.length > 0) {
          setOptions([...items]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, texto]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <AutocompleteCustomizado
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      sx={{ ...style, width: '100%', marginTop: '5px' }}
      isOptionEqualToValue={(option, value) => option.descricao === value.id}
      getOptionLabel={(option) => option.descricao}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <CampoDeTextoBase
          {...params}
          error={error}
          label={titulo}
          variant="standard"
          value={texto}
          onChange={(e) => setTexto(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export function CampoDeInputNumericoComSetas({
  style = {},
  onChange,
  value,
  label,
  error,
  onBlur = () => { },
  disabled,
  focusProximo,
  width = 220,
  maxValue = 9999999999999,
}) {
  // Aviso
  // Diferente dos outros campos de Input, por fazer o tratamento, este retorna diretamente o Valor, e não o
  // e.target.value como os outros.

  const propriedadesMaterialUIStandard = {
    id: 'standard-basic',
    variant: 'standard',
  };

  const [representacaoVisual, setRepresentacaoVisual] = useState(1);
  const { largura } = useTamanhoTela();

  useEffect(() => {
    if (value) {
      setRepresentacaoVisual(value);
    } else {
      setRepresentacaoVisual('');
    }
  }, [value]);

  function processar(dados) {
    if (onChange) {
      onChange(dados);
    } else if (onBlur) {
      onBlur(dados);
    }
  }

  function onChangeInterno(valorAtualizado) {
    let valor = valorAtualizado.target.value;

    processar(
      parseFloat(
        valor
          .replace('+', '')
          .replaceAll('-', '')
          .replaceAll(',', '')
          .replaceAll('.', '')
      )
    );
    setRepresentacaoVisual(valor);
  }

  function onBlurInterno(valorAtualizado) {
    let valor = valorAtualizado.target.value;

    processar(
      parseFloat(
        valor
          .replace('+', '')
          .replaceAll('-', '')
          .replaceAll(',', '')
          .replaceAll('.', '')
      )
    );
    setRepresentacaoVisual(valor);
  }

  function adicionarAoValor(e) {
    if (value >= maxValue) {
      return;
    }
    e.stopPropagation();
    processar(value >= 1 ? value + 1 : 1);
    setRepresentacaoVisual(
      representacaoVisual >= 1 ? representacaoVisual + 1 : 1
    );
  }

  function diminuirAoValor(e) {
    e.stopPropagation();
    processar(value >= 2 ? value - 1 : 1);
    setRepresentacaoVisual(
      representacaoVisual >= 2 ? representacaoVisual - 1 : 1
    );
  }

  return (
    <NumericFormatCustomizado
      disabled={disabled}
      style={{ pointerEvents: disabled ? 'none' : 'all', width: '100%' }}
    >
      <NumericFormatInput
        // {...rest}
        style={{
          ...style,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        label={label}
        onChange={onChange ? onChangeInterno : () => { }}
        onBlur={focusProximo || onBlur ? onBlurInterno : () => { }}
        value={representacaoVisual}
        allowNegative={false}
        allowLeadingZeros={false}
        min={1}
        error={error}
        customInput={TextFieldMonetario}
        disabled={disabled}
        {...propriedadesMaterialUIStandard}
      />
      <IconeBotao
        className="sub botao"
        onClick={(event) => (disabled ? {} : diminuirAoValor(event))}
        style={{ fontSize: largura > 768 ? '18px' : '24px' }}
      >
        <FontAwesomeIcon icon={faMinus} />
      </IconeBotao>
      <IconeBotao
        className="add botao"
        onClick={(event) => (disabled ? {} : adicionarAoValor(event))}
        style={{ fontSize: largura > 768 ? '18px' : '24px' }}
      >
        <FontAwesomeIcon icon={faPlus} />
      </IconeBotao>
    </NumericFormatCustomizado>
  );
}
export function CampoDeInputNumericoComSetasZero({
  style = {},
  onChange,
  value,
  label,
  error,
  onBlur = () => { },
  disabled,
  focusProximo,
  width = 220,
  maxValue = 9999999999999,
}) {
  // Aviso
  // Diferente dos outros campos de Input, por fazer o tratamento, este retorna diretamente o Valor, e não o
  // e.target.value como os outros.

  const propriedadesMaterialUIStandard = {
    id: "standard-basic",
    variant: "standard",
  };

  const [representacaoVisual, setRepresentacaoVisual] = useState(0);
  const { largura } = useTamanhoTela();

  useEffect(() => {
    if (value) {
      setRepresentacaoVisual(value);
    } else {
      setRepresentacaoVisual("0");
    }
  }, [value]);

  function processar(dados) {
    if (onChange) {
      onChange(dados);
    } else if (onBlur) {
      onBlur(dados);
    }
  }

  function onChangeInterno(valorAtualizado) {
    let valor = valorAtualizado.target.value;

    processar(
      parseFloat(
        valor
          .replace("+", "")
          .replaceAll("-", "")
          .replaceAll(",", "")
          .replaceAll(".", "")
      )
    );
    setRepresentacaoVisual(valor);
  }

  function onBlurInterno(valorAtualizado) {
    let valor = valorAtualizado.target.value;

    processar(
      parseFloat(
        valor
          .replace("+", "")
          .replaceAll("-", "")
          .replaceAll(",", "")
          .replaceAll(".", "")
      )
    );
    setRepresentacaoVisual(valor);
  }

  function adicionarAoValor(e) {
    if (value >= maxValue) {
      return;
    }
    e.stopPropagation();
    processar(value >= 0 ? value + 1 : 0);
    setRepresentacaoVisual(
      representacaoVisual >= 0 ? representacaoVisual + 1 : 0
    );
  }

  function diminuirAoValor(e) {
    e.stopPropagation();
    processar(value > 0 ? value - 1 : 0);
    setRepresentacaoVisual(
      representacaoVisual > 0 ? representacaoVisual - 1 : 0
    );
  }

  return (
    <NumericFormatCustomizado
      disabled={disabled}
      style={{ pointerEvents: disabled ? "none" : "all", width: "100%" }}
    >
      <NumericFormatInput
        // {...rest}
        style={{
          ...style,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        label={label}
        onChange={onChange ? onChangeInterno : () => { }}
        onBlur={focusProximo || onBlur ? onBlurInterno : () => { }}
        value={representacaoVisual}
        allowNegative={false}
        allowLeadingZeros={false}
        min={1}
        error={error}
        customInput={TextFieldMonetario}
        disabled={disabled}
        {...propriedadesMaterialUIStandard}
      />
      <IconeBotao
        className="sub botao"
        onClick={(event) => (disabled ? {} : diminuirAoValor(event))}
        style={{ fontSize: largura > 768 ? "18px" : "24px" }}
      >
        <FontAwesomeIcon icon={faMinus} />
      </IconeBotao>
      <IconeBotao
        className="add botao"
        onClick={(event) => (disabled ? {} : adicionarAoValor(event))}
        style={{ fontSize: largura > 768 ? "18px" : "24px" }}
      >
        <FontAwesomeIcon icon={faPlus} />
      </IconeBotao>
    </NumericFormatCustomizado>
  );
}

export const CampoDeInputNumericoAlternativo = ({
  titulo,
  fundoTransparente = false,
  style,
  value,
  tabindex,
  refs,
  typeNumber = false,
  ocultarSetas = false,
  focusProximo,
  onBlur,
  disabled = false,
  variant = "standard",
  onKeyDown = () => { },
  className = "",
  type = "text",
  maxlength = 999,
  register,
  ...rest
}) => {
  return (
    <CampoDeInputNumericoAlternativoBase
      {...rest}
      className={className}
      typeNumber={typeNumber}
      label={titulo}
      $fundoTransparente={fundoTransparente}
      variant={variant}
      style={{
        ...style,
        opacity: disabled ? 0.7 : 1,
        cursor: disabled ? "not-allowed" : "default",
      }}
      value={value}
      onBlur={focusProximo || onBlur}
      inputProps={{ tabIndex: tabindex }}
      ref={refs}
      ocultarSetas={ocultarSetas}
      onKeyDown={onKeyDown}
      type={type}
      maxlength={maxlength}
      disabled={disabled}
      {...register}
    />
  );
};

export function CampoDeInputPorcentagem({
  style = {},
  onChange,
  value,
  label,
  error,
  onBlur = () => { },
  disabled,
  refs,
  noLimit100 = false,
  permitirZero = false,
  limit99 = false,
}) {
  const [valorInicialSetado, setValorInicialSetado] = useState(false);
  const [representacaoVisual, setRepresentacaoVisual] = useState("");

  const propriedadesMaterialUIStandard = {
    id: "standard-basic",
    variant: "standard",
    InputProps: {
      // startAdornment: <InputAdornment position="start">%</InputAdornment>,
      placeholder: "%",
    },
  };

  function onChangeInterno(valorAtualizado) {
    let valor = parseFloat(valorAtualizado.target.value.replace('%', ''));

    if (isNaN(valor) && permitirZero && valorAtualizado.target.value === "0") {
      valor = 0;
    }

    onChange(valor);
    setRepresentacaoVisual(valorAtualizado.target.value);
  }

  const formatCurrencyByEnd = (value) => {
    if (!Number(value) && !permitirZero) return "";
    if ((parseFloat(value) / 100 > 99.99) & limit99) return "99.99%";
    if (parseFloat(value) / 100 > 100 && !noLimit100) return "100.00%";

    const amount = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
    }).format(parseFloat(value) / 100);

    return `${amount.replace(',', '.')}%`;
  };

  useEffect(() => {
    if (value === "" || (value === 0 && !permitirZero)) {
      setRepresentacaoVisual("");
    } else {
      setRepresentacaoVisual(
        value?.toLocaleString("pt-br", { minimumFractionDigits: 2 })
      );
    }
  }, [value, permitirZero]);

  useEffect(() => {
    if (!valorInicialSetado) {
      if (value !== undefined && value !== null) {
        setValorInicialSetado(true);
        setRepresentacaoVisual(
          value.toLocaleString('pt-br', { minimumFractionDigits: 2 })
        );
      }
    }
  });

  return (
    <NumberFormatBaseInput
      className="input-CampoDeInputPorcentagem"
      format={formatCurrencyByEnd}
      style={{ ...style }}
      label={label}
      onChange={onChangeInterno}
      onBlur={onBlur}
      value={representacaoVisual}
      thousandSeparator="."
      decimalSeparator=","
      suffix=" %"
      maxLength={5}
      decimalScale={2}
      fixedDecimalScale
      error={error}
      customInput={TextFieldMonetario}
      disabled={disabled}
      ref={refs}
      {...propriedadesMaterialUIStandard}
    />
  );
}

export const CampoDeHora = ({ titulo, style, error, onBlur, ...rest }) => {
  return (
    <ContainerData style={{ ...style }}>
      <CampoDeHoraBase
        {...rest}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        label={titulo}
        variant="standart"
        slotProps={{
          textField: { variant: "standard", error: error, onBlur: onBlur },
        }}
      />
    </ContainerData>
  );
};

export const CampoDeDataSemHora = ({
  titulo,
  style,
  error,
  refs,
  limparCampo,
  ...rest
}) => {
  return (
    <CampoDeDataSemHoraBase
      {...rest}
      ref={refs}
      label={titulo}
      variant="standart"
      dayOfWeekFormatter={(day) => day.charAt(0).toUpperCase()}
      slotProps={{
        textField: {
          variant: 'standard',
          error: error,
        },
        ...(limparCampo
          ? {
            field: {
              clearable: true,
              onClear: limparCampo,
            },
          }
          : {}),
      }}
    />
  );
};

export const DescricaoErro = ({
  erro,
  fontSize,
  marginBottom,
  style,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        marginBottom: marginBottom ? marginBottom : 16,
        position: "relative",
      }}
    >
      <p
        {...rest}
        style={{
          color: theme.cores.semanticDanger2,
          fontSize: fontSize ? fontSize : 14,
          marginBottom: 8,
          fontWeight: 'bold',
          marginTop: 8,
          position: "absolute",
          bottom: "-28px",
          ...style,
        }}
      >
        {erro?.message}
      </p>
    </div>
  );
};

export const CampoDeTextoMeioPublicidade = ({
  titulo,
  fundoTransparente = false,
  style,
  value,
  ...rest
}) => {
  return (
    <CampoDeTextoCustom
      {...rest}
      label={titulo}
      variant="standard"
      style={style}
      value={value}
    />
  );
};

export function CampoDeInputPorcentagemWayservice({
  style = {},
  onChange,
  value,
  label,
  error,
  onBlur = () => { },
  disabled,
  refs,
  className,
}) {
  // Aviso
  // Diferente dos outros campos de Input, por fazer o tratamento, este retorna diretamente o Valor, e não o
  // e.target.value como os outros.

  const [valorInicialSetado, setValorInicialSetado] = useState();

  const propriedadesMaterialUIStandard = {
    id: 'standard-basic',
    variant: 'standard',

    InputProps: {
      InputAdornment: <InputAdornment position="start">%</InputAdornment>,
      placeholder: '%',
    },
  };

  const [representacaoVisual, setRepresentacaoVisual] = useState('');

  function onChangeInterno(valorAtualizado) {
    let valor = parseFloat(valorAtualizado.target.value.replace('%', ''));

    onChange(valor);

    setRepresentacaoVisual(valorAtualizado.target.value);
  }

  const formatCurrencyByEnd = (value) => {
    if (!Number(value)) return '';

    if (parseFloat(value) / 100 > 100) return '100.00%';

    const amount = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
    }).format(parseFloat(value) / 100);

    return `${amount.replace(',', '.')}%`;
  };

  useEffect(() => {
    if (value == '') {
      setRepresentacaoVisual('');
    }
  }, [value]);

  useEffect(() => {
    if (!valorInicialSetado) {
      if (value != undefined) {
        setValorInicialSetado(true);
        setRepresentacaoVisual(
          value.toLocaleString('pt-br', { minimumFractionDigits: 2 })
        );
      }
    }
  });

  return (
    <InputPorcentagemWayservice
      style={{ ...style }}
      className={className}
      format={formatCurrencyByEnd}
      label={label}
      //onValueChange={onChangeInterno}
      onChange={onChangeInterno}
      onBlur={onBlur}
      value={representacaoVisual}
      thousandSeparator="."
      decimalSeparator=","
      sufix=" %"
      maxLength={5}
      max={5}
      decimalScale={2}
      fixedDecimalScale
      error={error}
      customInput={TextFieldMonetario}
      disabled={disabled}
      {...propriedadesMaterialUIStandard}
    />
  );
}

export const CampoDeSelecaoTelaCardapio = ({
  titulo,
  referenciaNome,
  referenciaValor,
  valor,
  onChange,
  items = [],
  campoVazio = true,
  iconeSearch = false,
  cor = null,
  refs,
  classes = null,
  cardapioAvancado = false,
  ...rest
}) => {
  const theme = useTheme();
  // Modelo de items:
  // Lista de Strings: ['item1', 'item2', 'item3']
  // Lista de Objetos: [
  //   {titulo: 'item1', valor: 'valor1'},
  //   {titulo: 'item2', valor: 'valor2'},
  //   {titulo: 'item3', valor: 'valor3'},
  // ]

  function GerenciarObjetosOuTextos() {
    return items.map((item, index) => {
      if (typeof item === 'string') {
        return (
          <MenuItem
            key={titulo + item + index}
            value={item}
            className={cardapioAvancado && 'cardapioAvancado'}
          >
            {item}
          </MenuItem>
        );
      } else if (typeof item === 'object') {
        return (
          <MenuItem
            style={{ marginLeft: 50 }}
            key={
              titulo + item[referenciaValor ? referenciaValor : 'valor'] + index
            }
            value={item[referenciaValor ? referenciaValor : "valor"]}
            className={cardapioAvancado && "cardapioAvancado"}
          >
            {item[referenciaNome ? referenciaNome : 'titulo']}
          </MenuItem>
        );
      }
    });
  }

  return (
    <FormularioCustomizadoSelecao
      variant="standard"
      {...rest}
      value={valor}
      cor={cor}
    >
      <InputLabel
        style={{
          display: iconeSearch ? 'flex' : '',
          alignItems: iconeSearch ? 'center' : '',
          gap: iconeSearch ? 10 : '',
          paddingLeft: iconeSearch ? 16 : '',
          height: '35px',
        }}
      >
        {iconeSearch && (
          <FontAwesomeIcon
            icon={faSearch}
            style={{
              color: theme.adaptativo('neutral50', 'neutral100'),
              width: 15,
              height: 15,
              weight: 'bold',
            }}
          />
        )}
        {titulo}
      </InputLabel>
      <Select
        id="zeca"
        className={classes}
        ref={refs}
        value={items.length > 0 && valor ? valor : ''}
        label={titulo}
        onChange={onChange}
        style={{ height: '35px', color: `rgb(${cor})` }}
        MenuProps={{ variant: 'menu' }}
      >
        {campoVazio && <MenuItem value={''}>Vazio</MenuItem>};
        {GerenciarObjetosOuTextos()}
      </Select>
    </FormularioCustomizadoSelecao>
  );
};

export const CampoCor = ({
  value,
  onChange,
  style,
  label,
  disabled = false,
  ...rest
}) => {
  return (
    <CampoCorBase
      label={label}
      variant="standard"
      style={{
        ...style,
        opacity: disabled ? 0.7 : 1,
        cursor: disabled ? 'not-allowed' : 'default',
      }}
      value={value}
      onChange={onChange}
      disabled={disabled}
      {...rest}
    />
  );
};

export function CampoDeInputPixel({
  style = {},
  onChange,
  value,
  label,
  error,
  onBlur = () => { },
  disabled,
  refs,
}) {
  // Aviso
  // Diferente dos outros campos de Input, por fazer o tratamento, este retorna diretamente o Valor, e não o
  // e.target.value como os outros.

  const [valorInicialSetado, setValorInicialSetado] = useState(false);

  const propriedadesMaterialUIStandard = {
    id: 'standard-basic',
    variant: 'standard',

    InputProps: {
      InputAdornment: <InputAdornment position="start">px</InputAdornment>,
      placeholder: 'px',
    },
  };

  const [representacaoVisual, setRepresentacaoVisual] = useState('');

  function onChangeInterno(valorAtualizado) {
    let valor = parseFloat(valorAtualizado.target.value);

    onChange(valor);

    setRepresentacaoVisual(valorAtualizado.target.value);
  }

  const formatCurrencyByEnd = (value) => {
    if (!Number(value)) return '';

    if (parseFloat(value).toFixed(2) > 999000.0) {
      toast.warning(`O valor máximo é de 1000.00px`);
      return '1000.00';
    }

    const amount = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
    }).format(parseFloat(value) / 100);

    return `${amount.replace(',', '.')}`;
  };

  useEffect(() => {
    if (value == '') {
      setRepresentacaoVisual('');
    }
  }, [value]);

  useEffect(() => {
    if (!valorInicialSetado) {
      if (value != undefined) {
        setValorInicialSetado(true);
        setRepresentacaoVisual(
          value.toLocaleString('pt-br', { minimumFractionDigits: 2 })
        );
      }
    }
  });

  return (
    <NumberFormatBaseInput
      className="input-CampoDeInputPorcentagem"
      format={formatCurrencyByEnd}
      label={label}
      onChange={onChangeInterno}
      onBlur={onBlur}
      value={representacaoVisual}
      thousandSeparator="."
      decimalSeparator=","
      sufix=" %"
      maxLength={5}
      max={5}
      decimalScale={2}
      fixedDecimalScale
      error={error}
      customInput={TextFieldMonetario}
      disabled={disabled}
      ref={refs}
      {...propriedadesMaterialUIStandard}
      style={{
        ...style,
        opacity: disabled ? 0.7 : 1,
        cursor: disabled ? 'not-allowed' : 'default',
      }}
    />
  );
}

export function CampoDeInputNumeroDecimal({
  style = {},
  onChange = () => { },
  value,
  label,
  titulo,
  error,
  onBlur = () => { },
  pedidoCompra = false,
  valorMax = 99999999.99,
  tabindex,
  refs,
  classess = "",
  disabled = false,
}) {
  const [valorInicialSetado, setValorInicialSetado] = useState(false);
  // Aviso
  // Diferente dos outros campos de Input, por fazer o tratamento, este retorna diretamente o Valor, e não o
  // e.target.value como os outros.

  const propriedadesMaterialUIStandard = {
    id: "standard-basic",
    variant: "standard",
    placeholder: "0,00",
    InputProps: {
      placeholder: "0,00",
    },
  };

  const [representacaoVisual, setRepresentacaoVisual] = useState(
    value != "" && value != 0 && value != undefined
      ? String(value.toFixed(3)).replace(".", ",")
      : ""
  );

  useEffect(() => {
    if (value == "") {
      setRepresentacaoVisual("");
    }
  }, [value]);

  function onChangeInterno(valorAtualizado) {
    let valor = parseFloat(
      valorAtualizado.target.value
        .replace("R$", "")
        .replaceAll(".", "")
        .replaceAll(",", ".")
    );

    valor = tratarLimiteValor(valor);

    onChange(valor);

    setRepresentacaoVisual(valorAtualizado.target.value);
  }

  function tratarLimiteValor(valor) {
    let novoValor = valor;
    novoValor = tratarValorMax(novoValor);
    return novoValor;
  }

  function tratarValorMax(valor) {
    if (valorMax || pedidoCompra) {
      const valorMaxAtualizado = pedidoCompra ? 99999.99 : valorMax;
      let max = valorMaxAtualizado * 100;
      if (valor > max) {
        return max;
      }
    }

    return valor;
  }

  const formatCurrencyByEnd = (value) => {
    if (!Number(value)) return "";

    let valor = tratarLimiteValor(value);

    const amount = new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 3,
    }).format(parseFloat(valor) / 1000);

    return `${amount}`;
  };

  return (
    <NumberFormatBaseInput
      className={`input-CampoDeInputNumeroDecimal ${classess}`}
      // {...rest}
      format={formatCurrencyByEnd}
      style={{
        ...style,
        opacity: disabled ? 0.7 : 1,
        cursor: disabled ? "not-allowed" : "default",
      }}
      label={label || titulo}
      //onValueChange={onChangeInterno}
      onChange={onChangeInterno}
      onKeyDown={(e) => {
        if (e.key == "Backspace") {
          const input = document.getElementById("standard-basic");

          let len = input.value.length;

          setTimeout(() => {
            if (input.setSelectionRange) {
              input.focus();
              input.setSelectionRange(len, len);
            } else if (input.createTextRange) {
              let t = input.createTextRange();
              t.collapse(true);
              t.moveEnd("character", len);
              t.moveStart("character", len);
              t.select();
            }
          }, 1);
        }
      }}
      onBlur={onBlur}
      value={representacaoVisual}
      allowEmptyFormatting={false}
      error={error}
      customInput={TextFieldMonetario}
      disabled={disabled}
      {...propriedadesMaterialUIStandard}
      ref={refs}
      InputProps={{ tabIndex: tabindex }}
    />
  );
}
