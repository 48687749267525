import { createContext, useEffect, useState, useContext } from "react";
import { useApi, baseURL, versaoClienteDTO, baseUrlAssets } from "./api";
import axios from "axios";
import {
  faSearch,
  faPlus,
  faChartPie,
  faTruck,
  faFileLines,
  faConciergeBell,
  faShield,
  faMagnifyingGlass,
  faCommentsDollar,
  faUtensils,
  faFileContract,
  faCashRegister,
  faUser,
  faLaptop,
  faSquarePlus,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useIsDev from "../utils/useIsDev";
import { useAuth } from "./auth";

export const MenuContext = createContext({});

export const MenuProvider = ({ children }) => {
  const navigate = useNavigate();
  const { get, post, put, token } = useApi();
  const { isDev } = useIsDev();
  const { homologacao } = useAuth();
  const url = "http://homolog.erp.waybe.com.br";
  const sessao = JSON.parse(localStorage.getItem("sessao"));

  let arrayContratosAtivos;
  let arrayListaPrivilegios;
  let tipoPerfilUsuario;
  let tipoEmpresa;
  let permissoesWaybe;

  if (sessao) {
    arrayContratosAtivos = sessao.contratoWaychefAtualDTO.listaItens;
    permissoesWaybe = sessao?.contratoWaybeAtualDTO?.permissoesWaybe;
    arrayListaPrivilegios =
      sessao?.usuarioLogadoDTO?.usuarioPerfilEmpresaDTO?.listaPrivilegios;
    tipoPerfilUsuario =
      sessao?.usuarioLogadoDTO?.usuarioPerfilEmpresaDTO?.tipoPerfilUsuario;
    tipoEmpresa = sessao?.empresaDTO?.tipoEmpresa;
  }

  const homologacaoFormatada = homologacao
    ? true
    : baseURL.includes("192")
      ? true
      : false;

  const dadosBackup = {
    menus: {
      "Dashboard": {
        "ativo": true,
        "icone": faChartPie,
        "filhos": {},
      },
      "Contrato": {
        "icone": faFileContract,
        "ativo": true,
        "filhos": {
          "Waychef": {
            "Planos padrão": () =>
              navigate("/contrato/plataforma/waychef/planos"),
            "Planos": () => navigate("/contrato/integrador/waychef/planos"),
          },
          "Waymenu": {
            "Planos padrão": () =>
              navigate("/contrato/plataforma/waymenu/planos"),
            "Planos": () => navigate("/contrato/integrador/waymenu/planos"),
          },
          "Extras": () => navigate("contrato/integrador/extras"),
          "Extras padrão": () => navigate("contrato/plataforma/extras"),
          "Controle de Contratos": () =>
            navigate("/empresas/controle-contratos"),
        },
      },
      "Gerenciamento assinatura": {
        "icone": faCashRegister,
        "ativo": true,
        "filhos": {
          "Pagamento": () => navigate("/gerenciar-plano"),
        },
      },
      "Cadastros": {
        "icone": faPlus,
        "filhos": {
          "Produtos": {
            "Produtos": () => {
              window.location.href = `${url}/cadastro/produto`;
            },
            "Grupos": () => {
              window.location.href = `${url}/cadastro/produto/grupo`;
            },
            "Locais de Produção": () => {
              window.location.href = `${url}/cadastro/produto/local-producao`;
            },
            "Menus": () => {
              window.location.href = `${url}/cadastro/produto/menus`;
            },
            "Observações": () => {
              window.location.href = `${url}/cadastro/produto/observacao`;
            },
            "Tabela de Preços": () => {
              window.location.href = `${url}/cadastro/produto/tabela-preco`;
            },
            "Variações": () => {
              window.location.href = `${url}/cadastro/telas_produto/tamanho.xhtml`;
            },
            "Cardápio": {
              "Autoatendimento": () => {
                window.location.href = `${url}/cadastro/waychef/cardapio-totem`;
              },
              "Waychef": () => {
                window.location.href = `${url}/cadastro/waychef/cardapio`;
              },
              "Ifood": () => {
                window.location.href = `${url}/cadastro/waychef/cardapio-ifood`;
              },
            },
          },
          "Clientes": {
            "Clientes": () => {
              window.location.href = `${url}/cadastro/cliente`;
            },
            "Classificações": () => {
              window.location.href = `${url}/cadastro/cliente/classificacao`;
            },
            "Ramo de atividade": () => {
              window.location.href = `${url}/cadastro/ramo-atividade`;
            },
            "Regiões": () => {
              window.location.href = `${url}/cadastro/cliente/regiao`;
            },
            "Status de Crédito": () => {
              window.location.href = `${url}/cadastro/status-credito`;
            },
            "Waywallet": {
              "Carteira": () => {
                window.location.href = `${url}/financeiro/bandeira-cartao?o=waywallet`;
              },
              "Lojistas": () => {
                window.location.href = `${url}/waywallet/lojistas`;
              },
            },
          },
          "Finanças": {
            "Fornecedores e Credores": () => {
              window.location.href = `${url}/cadastro/fornecedor`;
            },
            "Representantes": () => {
              window.location.href = `${url}/cadastro/representante`;
            },
            "Cadastro de bandeiras": () => {
              window.location.href = `${url}/cadastro/telas_financeiro/financeiro/bandeira-cartao`;
            },
            "Categoria de sangria": () => {
              window.location.href = `${url}/cadastro/telas_financeiro/sangria_categoria`;
            },
            "Centro de Custos": () => {
              window.location.href = `${url}/financeiro/centro-custos`;
            },
            "Contas Correntes": () => {
              window.location.href = `${url}/financeiro/conta-corrente`;
            },
            "Plano de contas": () => {
              window.location.href = `${url}/financeiro/plano-contas`;
            },
          },
          "Fiscal": {
            "CFOP": () => {
              window.location.href = `${url}/fiscal/cop`;
            },
            "Classficação Fiscal Contribuinte - ICMS": () => {
              window.location.href = `${url}/cadastro/produto/fiscal/classificacao-fiscal-contribuinte`;
            },
            "ICMS": () => {
              window.location.href = `${url}/cadastro/produto/fiscal/icms`;
            },
            "PIS/COFINS": () => {
              window.location.href = `${url}/cadastro/produto/fiscal/pis-cofins`;
            },
            "Tributação por UF de Destino": () => {
              window.location.href = `${url}/cadastro/produto/fiscal/tributacao-por-uf-destino`;
            },
          },
          "Estoque": {
            "Local de estocagem": () => {
              window.location.href = `${url}/cadastro/produto/local-estocagem`;
            },
            "Setores": () => {
              window.location.href = `${url}/cadastro/telas_empresa/setor`;
            },
          },
        },
      },
      "Operacional": {
        "icone": faTruck,
        "filhos": {
          "Produtos": {
            "Mix de produtos": () => {
              window.location.href = `${url}/cadastro/produto/mix`;
            },
            "Promoções": () => {
              window.location.href = `${url}/cadastro/produto/promocao`;
            },
            "Remarcação de Preços": () => {
              window.location.href = `${url}/cadastro/produto/remarcacao-preco`;
            },
          },
          "Estoque": {
            "Inventário": () => {
              window.location.href = `${url}/lancamento/inventario`;
            },
            "Lançamentos": () => {
              window.location.href = `${url}/consulta/ajustes-estoque`;
            },
            "Produção": () => {
              window.location.href = `${url}/lancamento/estoque-producao`;
            },
            "Ajustes": {
              "Entrada Avançada": () => {
                window.location.href = `${url}/estoques/dci-entrada`;
              },
              "Saída Avançada": () => {
                window.location.href = `${url}/estoques/dci-saida`;
              },
              "Simples": () => {
                window.location.href = `${url}/lancamento/ajuste-estoque`;
              },
              "Zerar estoque": () => {
                window.location.href = `${url}/funcionalidades/zerar-estoque`;
              },
            },
            "Transferência": {
              "Externa": () => {
                window.location.href = `${url}/estoques/dci-transferencia`;
              },
              "Interna": () => {
                window.location.href = `${url}/lancamento/estoque-transferencia`;
              },
            },
          },
          "Fiscal": {
            "Consultar NF-e/NFS-e": () => {
              window.location.href = `${url}/nfe/consulta`;
            },
            "Gerenciador de NF-e": "/",
            "Gerenciador de NFC-e": "/",
            "Emitir NF-e/NFS-e": {
              "Nota Fiscal Complementar": () => {
                window.location.href = `${url}/nfe/nf_complementar`;
              },
              "Nota Fiscal de Entrada": () => {
                window.location.href = `${url}/nfe/emissao-entrada`;
              },
              "Nota Fiscal de Saída": () => {
                window.location.href = `${url}/nfe/emissao-saida`;
              },
            },
            "Lançar NF-e": {
              "Nota Fiscal de Entrada": () => {
                window.location.href = `${url}/nfe/lancamento-entrada`;
              },
            },
          },
          "Finanças": {
            "Controle de Cartões": {
              "Manutenção dos créditos": () => {
                window.location.href = `${url}/controle-cartoes/manutencao-credito`;
              },
              "Transações Realizadas": () => {
                window.location.href = `${url}/controle-cartoes/transacao-cartao`;
              },
            },
            "Movimentações": {
              "Cobrança bancaria": () => {
                window.location.href = `${url}/financeiro/remessa-bancaria`;
              },
              "Conferência de caixa": () => {
                window.location.href = `${url}/financeiro/conferencia-caixa`;
              },
              "Contas Correntes": () => {
                window.location.href = `${url}/financeiro/movimentacao-caixa`;
              },
              "Contas a pagar": () => {
                window.location.href = `${url}/financeiro/movimentacao-caixa?o=pagar`;
              },
              "Contas a receber": () => {
                window.location.href = `${url}/financeiro/movimentacao-caixa?o=receber`;
              },
              "Pagamento em lote": () => {
                window.location.href = `${url}/financas/movimentacoes/pagamento-lote`;
              },
              "Recebimento em lote": () => {
                window.location.href = `${url}/financas/movimentacoes/recebimento-lote`;
              },
            },
          },
          "Clientes": {
            "Fatura": {
              "Extrato conta": () => {
                window.location.href = `${url}/cliente/fatura/extrato`;
              },
              "Faturamento de vendas": () => {
                window.location.href = `${url}/cliente/fatura/faturamento`;
              },
              "Saldo de créditos": () => {
                window.location.href = `${url}/cliente/fatura/saldo-de-creditos`;
              },
            },
            "Relacionamento": {
              "Waywallet": {
                "Transações": () => {
                  window.location.href = `${url}/waywallet/transacoes`;
                },
              },
            },
          },
        },
      },
      "Relatórios": {
        "icone": faFileLines,
        "filhos": {
          "Cadastrais": {
            "Clientes": () => {
              window.location.href = `${url}/relatorios/cliente`;
            },
            "Ficha técnica": () => {
              window.location.href = `${url}/relatorios/relatorio_ficha_tecnica.xhtml`;
            },
            "Produtos": () => {
              window.location.href = `${url}/relatorios/produto`;
            },
            "Tributação": {
              "ICMS": () => {
                window.location.href = `${url}/relatorios/tributacao-icms`;
              },
              "PIS/COFINS": () => {
                window.location.href = `${url}/relatorios/tributacao-pis-cofins`;
              },
            },
          },
          "Estoque": {
            "Disponível": () => {
              window.location.href = `${url}/relatorios/estoque/disponivel`;
            },
            "Em falta": () => {
              window.location.href = `${url}/relatorios/estoque/em-falta`;
            },
            "Geral": () => {
              window.location.href = `${url}/relatorios/produto-estoque.xhtml`;
            },
            "Histórico de movimentação": () => {
              window.location.href = `${url}/relatorios/estoque/movimentacao-produto.xhtml`;
            },
            "Movimentação de insumos": () => {
              window.location.href = `${url}/relatorios/estoque/movimentacao-insumos`;
            },
          },
          "Faturamento": {
            "Cancelamentos": {
              "Itens cancelados": () => {
                window.location.href = `${url}/relatorios/itens-cancelados`;
              },
            },
          },
          "Fiscal": {
            "Listagem aux. Escrituração": () => {
              window.location.href = `${url}/relatorios/faturamento/tributacao/listagem-aux-escrituracao-fiscal`;
            },
          },
          "Monetário": {
            "Anual (mês a mês)": () => {
              window.location.href = `${url}/relatorios/faturamento-mensal`;
            },
            "Atendimentos": () => {
              window.location.href = `${url}/relatorios/atendimento`;
            },
            "Comissão do vendedor": () => {
              window.location.href = `${url}/relatorios/comissao-vendedor.xhtml`;
            },
            "Faturamento por período": () => {
              window.location.href = `${url}/relatorios/faturamento_periodo_dia.xhtml`;
            },
            "Forma de pagamento": () => {
              window.location.href = `${url}/relatorios/atendimento-finalizadora`;
            },
            "Por módulo": () => {
              window.location.href = `${url}/relatorios/faturamento/monetario/por-modulo`;
            },
            "Taxas de serviço": () => {
              window.location.href = `${url}/relatorios/faturamento/monetário/taxas-servico-vendedor`;
            },
          },
          "Módulos": {
            "Controle de tempo produção": () => {
              window.location.href = `${url}/relatorios/relatorio_controle_tempo_producao.xhtml`;
            },
            "Delivery": () => {
              window.location.href = `${url}/relatorios/atendimento-delivery`;
            },
          },
          "Produtos vendidos": {
            "Por grupo": () => {
              window.location.href = `${url}/relatorios/faturamento-por-grupo`;
            },
            "Por pacote": () => {
              window.location.href = `${url}/relatorios/pacotes-vendidos`;
            },
            "Por período": () => {
              window.location.href = `${url}/relatorios/produtos-vendidos`;
            },
            "Por tabela de preço": () => {
              window.location.href = `${url}/relatorios/produtos-vendidos-por-tabela`;
            },
            "Por vendedor": () => {
              window.location.href = `${url}/relatorios/produtos-vendidos-por-vendedor.xhtml`;
            },
          },
          "Análise Gerencial": {
            "DFC Mensal": () => {
              window.location.href = `${url}/financeiro/fluxo-caixa`;
            },
            "DRE Mensal": () => {
              window.location.href = `${url}/financeiro/dre-gerencial`;
            },
          },
        },
      },
      "Serviços": {
        "icone": faConciergeBell,
        "filhos": {
          "Serviços do sistema": () => {
            window.location.href = `${url}/configuracao/servicos`;
          },
          "Aplicações": () => {
            window.location.href = `${url}/configuracao/servicos`;
          },
        },
      },
      "Segurança": {
        "icone": faShield,
        "filhos": {
          "Configurações de usuário": {
            "Perfis de usuário": () => {
              window.location.href = `${url}/cadastro/telas_usuario/perfil_empresa.xhtml`;
            },
            "Usuário": () => {
              window.location.href = `${url}/cadastro/usuario`;
            },
          },
          "Dados da Empresa": {
            "Funcionários": () => {
              window.location.href = `${url}/cadastro/funcionario`;
            },
            "Minha empresa": () => {
              window.location.href = `${url}/configuracao/empresa`;
            },
            "Pagamento": () => {
              window.location.href = `${url}/empresa/gerenciar-assinatura`;
            },
          },
        },
      },
      "Consultas": {
        "icone": faMagnifyingGlass,
        "filhos": {
          "Atendimentos": () => {
            window.location.href = `${url}/consulta/atendimento`;
          },
          "Caixas": () => {
            window.location.href = `${url}/relatorios/operacao-caixa`;
          },
        },
      },
      "Fidelidade": {
        "icone": faCommentsDollar,
        "filhos": {
          "Cashback": () => {
            window.location.href = `/fidelidade/cashback`;
          },
        },
      },
    },
  };

  const dados = {
    menus: {
      "Dashboard": {
        "larguraMinima": 150,
        "ativo": true,
        "icone": faChartPie,
        "filhos": {
          "Novo": () => navigate("/dashboard/novo"),
        },
      },

      "Cadastros": {
        "larguraMinima": 140,
        "ativo": true,
        "icone": faSquarePlus,
        "filhos": {
          "Cardápios": {
            "Wayservice": () =>
              navigate("/produtos/cardapio/wayservice/listagem"),
          },
          ...(homologacaoFormatada
            ? {
              "Produtos": {
                "ativo": homologacaoFormatada ? true : false,
                "filhos": {
                  "Cadastro": () => navigate("/produtos/cadastro/listagem"),
                  "Parâmetros": {
                    "Grupos": () => navigate("/produtos/parametros/grupos"),
                    "Menus": () =>
                      navigate("/produtos/parametros/menus/listagem"),
                    "Observações": () =>
                      navigate("/produtos/parametros/observacoes"),
                  },
                },
              },
            }
            : null),
          ...(homologacaoFormatada
            ? {
              "Clientes": {
                "ativo": homologacaoFormatada ? true : false,
                "filhos": {
                  "Cadastro": () => navigate("/clientes/cadastro/listagem"),
                },
              },
            }
            : null),
          ...(permissoesWaybe?.includes("CONTROLE_CASHBACK")
            ? {
              "Fidelidade": {
                "ativo": true,
                "filhos": {
                  "Cashback": {
                    "Cadastros": () => navigate("/fidelidade/cashback"),
                    "Configurações": () =>
                      navigate("/fidelidade/cashback/configuracoes"),
                  },
                },
              },
            }
            : null),
          ...(homologacaoFormatada
            ? {
              "Minha Empresa": {
                "ativo": homologacaoFormatada ? true : false,
                "filhos": {
                  "Formas de pagamento": () =>
                    navigate("/minha-empresa/finalizadora"),
                  "Módulos": () => navigate("/parametros/modulos/listagem"),
                  "Funcionamento Empresa": () =>
                    navigate("/funcionamento-empresa"),
                },
              },
            }
            : null),
        },
      },
      "Consultas Rápidas": {
        "larguraMinima": 150,
        "ativo": true,
        "icone": faSearch,
        "filhos": {
          ...(Boolean(
            arrayListaPrivilegios?.CONS_ATENDIMENTOS ||
            tipoPerfilUsuario === "MASTER"
          )
            ? {
              "Atendimentos": () =>
                navigate("/consultas-rapidas/atendimentos"),
            }
            : null),
          ...(Boolean(
            arrayListaPrivilegios?.CONS_CAIXA || tipoPerfilUsuario === "MASTER"
          )
            ? {
              "Caixas": () => navigate("/consultas-rapidas/caixas"),
            }
            : null),
        },
      },
      "Operacional": {
        "larguraMinima": 150,
        "ativo": true,
        "icone": faUser,
        "filhos": {
          "Finanças": {
            "ativo": true,
            "filhos": {
              "Compras": {
                "Pedido de compras": () =>
                  navigate("/financas/compras/pedido-compras"),
                "Configurações": () =>
                  navigate("/financas/compras/configuracoes"),
              },
            },
          },
        },
      },

      "Relatórios": {
        "larguraMinima": 140,
        "ativo": true,
        "icone": faFileLines,
        "filhos": {
          ...(permissoesWaybe?.includes("CONTROLE_CASHBACK") &&
            Boolean(
              arrayListaPrivilegios?.PARAM_REL_CASHBACK ||
              tipoPerfilUsuario === "MASTER"
            )
            ? {
              "Cashback": () => navigate("/relatorios/cashback"),
            }
            : null),
          // "Produtos Vendidos": {
          //   "Top 10": () => navigate("/analise-relatorios/produtos-vendidos/top10")
          // },
          "Cadastrais": {
            ...(Boolean(
              arrayListaPrivilegios?.REL_CAD_CLIENTE ||
              tipoPerfilUsuario === "MASTER"
            )
              ? {
                "Clientes": () =>
                  navigate("analise-relatorios/cadastrais/clientes"),
              }
              : null),
            ...(Boolean(
              arrayListaPrivilegios?.REL_CAD_FICHA_TECNICA ||
              tipoPerfilUsuario === "MASTER"
            )
              ? {
                "Ficha Técnica": () =>
                  navigate("/analise-relatorios/cadastrais/ficha-tecnica"),
              }
              : null),
            "Produtos": () =>
              navigate("analise-relatorios/cadastrais/produtos"),
            "Tributação": {
              "PIS/COFINS": () =>
                navigate("/analise-relatorios/cadastrais/tributacao/piscofins"),
              "ICMS": () =>
                navigate("/analise-relatorios/cadastrais/tributacao/icms"),
            },
          },
          ...(tipoEmpresa === "FRANQUIA"
            ? {
              "Franquia": {
                "Rebate": () =>
                  navigate("/analise-relatorios/faturamento/franquia/rebate"),
              },
            }
            : null),
          "Fiscais": {
            ...(Boolean(
              arrayListaPrivilegios?.REL_FAT_FISCAIS ||
              tipoPerfilUsuario === "MASTER"
            )
              ? {
                "Listagem aux. Escrituração fiscal": () =>
                  navigate(
                    "/analise-relatorios/faturamento/fiscais/listagem-aux-escrituracao-fiscal"
                  ),
              }
              : null),
          },
          "Estoque": {
            ...(Boolean(
              arrayListaPrivilegios?.REL_ESTOQUE ||
              tipoPerfilUsuario === "MASTER"
            )
              ? {
                "Em falta": () =>
                  navigate("/analise-relatorios/estoque/em-falta"),
              }
              : null),
            ...(Boolean(
              arrayListaPrivilegios?.REL_ESTOQUE ||
              tipoPerfilUsuario === "MASTER"
            )
              ? {
                "Disponível": () =>
                  navigate("/analise-relatorios/estoque/disponivel"),
              }
              : null),
            ...(Boolean(
              arrayListaPrivilegios?.REL_ESTOQUE ||
              tipoPerfilUsuario === "MASTER"
            )
              ? {
                "Histórico de movimentações": () =>
                  navigate(
                    "/analise-relatorios/estoque/historico-movimentacoes"
                  ),
              }
              : null),
            ...(Boolean(
              arrayListaPrivilegios?.REL_ESTOQUE ||
              tipoPerfilUsuario === "MASTER"
            )
              ? {
                "Geral": () => navigate("/analise-relatorios/estoque/geral"),
              }
              : null),
            ...(Boolean(
              arrayListaPrivilegios?.REL_ESTOQUE ||
              tipoPerfilUsuario === "MASTER"
            )
              ? {
                "Movimentações de insumos": () =>
                  navigate(
                    "/analise-relatorios/estoque/movimentacoes-insumos"
                  ),
              }
              : null),
          },

          "Faturamento": {
            "Cancelamentos": {
              "Itens cancelados": () =>
                navigate(
                  "/analise-relatorios/faturamento/cancelamentos/itens-cancelados"
                ),
            },
            "Produtos Vendidos": {
              "Top 10": () =>
                navigate("/analise-relatorios/produtos-vendidos/top10"),
              "Por tabela de preço": () =>
                navigate(
                  "/analise-relatorios/faturamento/produtos-vendidos/tabela-preco"
                ),
              "Por pacote": () =>
                navigate(
                  "/analise-relatorios/faturamento/produtos-vendidos/por-pacote"
                ),
              "Por grupo": () => {
                navigate(
                  "/analise-relatorios/faturamento/produtos-vendidos/por-grupo"
                );
              },
              "Itens vendidos": () => {
                navigate(
                  "/analise-relatorios/faturamento/produtos-vendidos/itens-vendidos"
                );
              },
              ...(Boolean(
                arrayListaPrivilegios?.REL_FAT_MONETARIO ||
                tipoPerfilUsuario === "MASTER"
              )
                ? {
                  "Por período": () =>
                    navigate(
                      "/analise-relatorios/faturamento/produtos-vendidos/por-periodo"
                    ),
                }
                : null),
              ...(Boolean(
                arrayListaPrivilegios?.REL_FAT_MONETARIO ||
                tipoPerfilUsuario === "MASTER"
              )
                ? {
                  "Por vendedor": () =>
                    navigate(
                      "/analise-relatorios/faturamento/produtos-vendidos/por-vendedor"
                    ),
                }
                : null),
            },

            ...(Boolean(
              arrayListaPrivilegios?.REL_FAT_MODULOS ||
              tipoPerfilUsuario === "MASTER"
            )
              ? {
                "Módulos": {
                  "Delivery": () =>
                    navigate(
                      "/analise-relatorios/faturamento/modulos/delivery"
                    ),
                  "Controle de tempo produção": () =>
                    navigate(
                      "/analise-relatorios/faturamento/modulos/controle-tempo-de-producao"
                    ),
                },
              }
              : null),
            "Monetário": {
              ...(Boolean(
                arrayListaPrivilegios?.REL_FAT_MONETARIO ||
                tipoPerfilUsuario === "MASTER"
              )
                ? {
                  "Anual (mês a mês)": () =>
                    navigate(
                      "/analise-relatorios/faturamento/monetario/anual"
                    ),
                }
                : null),
              ...(Boolean(
                arrayListaPrivilegios?.REL_FAT_MONETARIO ||
                tipoPerfilUsuario === "MASTER"
              )
                ? {
                  "Atendimentos": () =>
                    navigate(
                      "/analise-relatorios/faturamento/monetario/atendimentos"
                    ),
                }
                : null),
              ...(Boolean(
                arrayListaPrivilegios?.REL_FAT_MONETARIO ||
                tipoPerfilUsuario === "MASTER"
              )
                ? {
                  "Taxas de serviço": () =>
                    navigate(
                      "/analise-relatorios/faturamento/monetario/taxas-servico"
                    ),
                }
                : null),
              ...(Boolean(
                arrayListaPrivilegios?.REL_FAT_MONETARIO ||
                tipoPerfilUsuario === "MASTER"
              )
                ? {
                  "Comissão do vendedor": () =>
                    navigate(
                      "/analise-relatorios/faturamento/monetario/comissao-vendedor"
                    ),
                }
                : null),
              ...(Boolean(
                arrayListaPrivilegios?.REL_FAT_MONETARIO ||
                tipoPerfilUsuario === "MASTER"
              )
                ? {
                  "Faturamento por período": () =>
                    navigate(
                      "/analise-relatorios/faturamento/monetario/por-periodo"
                    ),
                }
                : null),
              ...(Boolean(
                arrayListaPrivilegios?.REL_FAT_MONETARIO ||
                tipoPerfilUsuario === "MASTER"
              )
                ? {
                  "Por módulo": () =>
                    navigate(
                      "/analise-relatorios/faturamento/monetario/por-modulo"
                    ),
                }
                : null),
              ...(Boolean(
                arrayListaPrivilegios?.REL_FAT_MONETARIO ||
                tipoPerfilUsuario === "MASTER"
              )
                ? {
                  "Formas de pagamento": () =>
                    navigate(
                      "/analise-relatorios/faturamento/monetario/formas-de-pagamento"
                    ),
                }
                : null),
              ...(tipoEmpresa === "FRANQUIA"
                ? {
                  "Franquia": {
                    "Rebate": () =>
                      navigate(
                        "/analise-relatorios/faturamento/franquia/rebate"
                      ),
                  },
                }
                : null),
            },
          },
          "Finanças": {
            ...(Boolean(
              (arrayListaPrivilegios?.REL_FINANCAS &&
                arrayListaPrivilegios?.FIN_CAD_TIT_PAGAR) ||
              tipoPerfilUsuario === "MASTER"
            )
              ? {
                "Contas a Pagar": () =>
                  navigate("/analise-relatorios/financas/contas-a-pagar"),
              }
              : null),
            ...(Boolean(
              (arrayListaPrivilegios?.REL_FINANCAS &&
                arrayListaPrivilegios?.FIN_CAD_TIT_RECEBER) ||
              tipoPerfilUsuario === "MASTER"
            )
              ? {
                "Contas a Receber": () =>
                  navigate("/analise-relatorios/financas/contas-a-receber"),
              }
              : null),
          },
        },
      },

      "Contrato": {
        "icone": faFileContract,
        "ativo": true,
        "filhos": {
          "Waychef": {
            "Planos padrão": () =>
              navigate("/contrato/plataforma/waychef/planos"),
            "Planos": () => navigate("/contrato/integrador/waychef/planos"),
          },
          "Waymenu": {
            "Planos padrão": () =>
              navigate("/contrato/plataforma/waymenu/planos"),
            "Planos": () => navigate("/contrato/integrador/waymenu/planos"),
          },
          "Extras": () => navigate("contrato/integrador/extras"),
          "Extras padrão": () => navigate("contrato/plataforma/extras"),
          "Controle de Contratos": () =>
            navigate("/empresas/controle-contratos"),
        },
      },
      "Waymenu": {
        "larguraMinima": 140,
        "ativo": homologacaoFormatada ? true : false,
        "icone": faUtensils,
        "filhos": {
          "Campanhas": () => navigate("/campanhas"),
          "Cardápio": () => navigate("/cardapio/listagem"),
          "Entregas": () => navigate("/entregas"),
          "Funcionamento Empresa": () => navigate("/funcionamento-empresa"),
          "Pedidos": () => navigate("/pedidos"),
          "Banners": {
            "Cadastro": () => navigate("/banners/cadastro"),
          },
          "Cardápio Digital": {
            "Cardápio": () => navigate("/waymenu/redirect/cardapio"),
            "Delivery": () => navigate("/waymenu/redirect/delivery"),
          },
          "Cupons": {
            "Cadastro": () => navigate("/cupons/"),
            "Dados principais": () => navigate("/cupons/dados"),
          },
          "Parâmetros": {
            "Módulos": () => navigate("/parametros/modulos/listagem"),
          },
          "Vantagens": {
            "Cadastro": () => navigate("/vantagens/cadastro"),
            "Categorias": () => navigate("/vantagens/categorias"),
          },
        },
      },
      "Relatórios Plataforma": {
        "larguraMinima": 150,
        "ativo": false,
        "icone": faFileLines,
        "filhos": {
          "Transações": () => navigate("/analise-relatorios/transacoes"),
          "Faturamento Integradores": () =>
            navigate("/analise-relatorios/faturamento-integradores"),
        },
      },

      "Serviços": {
        "larguraMinima": 140,
        "ativo": true,
        "icone": faLaptop,
        "filhos": {
          ...(Boolean(
            arrayListaPrivilegios?.PARAM_SERVICOS_SISTEMA ||
            tipoPerfilUsuario === "MASTER"
          )
            ? {
              "Serviços do Sistema": () =>
                navigate("/servicos/servicosdosistema"),
            }
            : { "Em dev": {} }),
        },
      },
      "Segurança": {
        "larguraMinima": 150,
        "ativo": false,
        "icone": faLock,
        "filhos": {
          "Em dev": {},
        },
      },
      "Consultas": {
        "larguraMinima": 150,
        "ativo": false,
        "icone": faMagnifyingGlass,
        "filhos": {
          "Em dev": {},
        },
      },
    },
  };
  const [listaMenu, setListaMenu] = useState(dados);
  const [loadingMenu, setLoadingMenu] = useState(true);
  const [headerFixo, setHeaderFixo] = useState(false);
  const [carregandoDashboard, setCarregandoDashboard] = useState(true);
  const [dadosEmpresa, setDadosEmpresa] = useState({});
  const [dadosUsuario, setDadosUsuario] = useState({});
  const [listaDashboards, setListaDashboards] = useState();

  function atualizarHeaderFixo(status) {
    try {
      setHeaderFixo(status);
    } catch (e) { }
  }

  useEffect(() => {
    if (sessao) {
      setListaMenu(dados);
    }
  }, []);

  useEffect(() => {
    if (sessao) {
      setListaMenu(dados);
    }
  }, [homologacao]);

  function atualizarHeaderFixo(status) {
    try {
      setHeaderFixo(status);
    } catch (e) { }
  }

  function buscarDashboards(tokenAtual = token, montarMenu = true) {
    return new Promise(async (resolve, reject) => {
      let api = axios.create({
        baseURL: baseURL,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": `Bearer ${tokenAtual}`,
        },
      });
      await api
        .get("/relatorios/dashboard")
        .then((dados) => {
          console.log('CHEGOU NA BUCA', dados.data)
          localStorage.setItem("lista_dashboards", JSON.stringify(dados.data));

          //Verificar se deve montar menu, ou está apenas atualizando lista
          if (montarMenu) {
            console.log('CHEGOU VAI')
            buscarDashboardsStorage();
          }
          resolve(dados);
        })
        .catch((error) => {
          console.log('CHEGOU NA ERRO')
          resolve([]);
        });
    });
  }

  async function buscarDashboardsStorage() {
    try {
      //verificar se existem itens no localstorage, caso nao existir buscar
      const dashboards = localStorage.getItem("lista_dashboards");
      console.log('FOI', dashboards)
      if (dashboards == null || dashboards == '""') {
        console.log('TA CAINDO NESSE')
        //buscarDashboards();
        return;
      }

      montarDashboards(JSON.parse(dashboards));
    } catch (e) {
      //buscarDashboards();
    }
  }

  const montarDashboards = (lista) => {
    try {
      let dashboards = {};

      if (typeof lista === "object") {
        lista.map((item) => {
          dashboards[item.nome] = () => {
            navigate(`/dashboard/${item.urlAmigavel}`);
            navigate(0);
          };
        });
      }

      dashboards["Novo"] = () => {
        navigate("/dashboard/novo");
      };

      setListaDashboards(dashboards);
    } catch (e) {
      buscarDashboards();
    }
  };

  const buscarDadosEmpresaStorage = async () => {
    try {
      setDadosEmpresa({});
      setDadosUsuario({});
      setDadosEmpresa(JSON.parse(localStorage.getItem("_da")).empresaDTO);
      setDadosUsuario(JSON.parse(localStorage.getItem("_da")).usuarioLogadoDTO);
    } catch (error) {
      toast.error("Não foi possivel carregar os dados de sua empresa!");
    }
  };

  return (
    <MenuContext.Provider
      value={{
        listaMenu,
        headerFixo,
        atualizarHeaderFixo,
        buscarDashboards,
        setCarregandoDashboard,
        carregandoDashboard,
        buscarDashboardsStorage,
        dadosEmpresa,
        buscarDadosEmpresaStorage,
        dadosUsuario,
        listaDashboards,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = () => useContext(MenuContext);
