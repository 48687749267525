import styled from "@emotion/styled";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.cores.surfaceBackground1};
  margin: 15px 20px 80px 20px;

  @media (max-width: 768px) {
    margin: 0px 20px 80px 20px;
  }
`;

export const Painel = styled.div`
  margin-top: 10px;
  width: 100%;
  background-color: ${(props) => props.theme.cores.surface2};
  //background-color: red;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 0px 0px;

  h3 {
    font-size: 33px;
    font-weight: 400;
    margin-bottom: 26px;
  }

  @media (max-width: 768px) {
    margin-bottom: 100px;
    padding: 38px 8px;
    h3 {
      font-size: 23px;
    }
  }
`;

export const CabecalhoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 85px;
  padding-top: 45px;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const CabecalhoContainerMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 65px;
    padding-top: 13px;
    margin-bottom: 19px;
  }
`;

export const CabecalhoComBotao = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CabecalhoComBotaoMobile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconeBotao = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;

  &:hover {
    opacity: 0.7;
    transition: 0.2s;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  svg {
  }
`;

export const TextoCabecalhoComBotao = styled.p`
  font-family: "Comfortaa", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 28.99px;
  max-width: 70vw;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 300px) {
    font-size: 14px;
  }
`;
export const BotaoCabecalho = styled.button`
  height: 40px;
  width: 208px;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.cores.actionActive};
  cursor: pointer;
  border: none;

  &:hover {
    opacity: 0.7;
    color: ${(props) => props.theme.cores.actionHover};
    transition: 0.2s;
  }

  @media (max-width: 768px) {
    height: 32px;
    width: 157px;
  }
`;

export const TextoBotaoCabecalho = styled.p`
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #dce3e9;
`;

export const LinhaCabecalhoComBotao = styled.hr`
  width: 100%;
`;

export const ContainerCardsLoja = styled.div`
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  padding-bottom: 2rem;
  padding: 35px;

  @media (max-width: 768px) {
    gap: 16px;
    flex-direction: column;
    align-items: center;
  }
`;

export const CardLoja = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.cores.corCardLoja};
  ${(props) =>
    props.empresaSelecionada &&
    `border: solid 2.5px ${props.theme.cores.brand40Default}`};
  width: 23%;
  max-width: 450px;
  height: 15vh;
  max-height: 200px;
  min-height: 100px;
  min-width: 200px;
  box-shadow: ${(props) =>
    props.empresaSelecionada
      ? `6px 6px 16px rgba(0, 0, 0, 0.18);`
      : `0px 2px 10px rgba(0, 0, 0, 0.16);`};
  border-radius: 10px;
  ${(props) => !props.empresaSelecionada && `opacity: 0.7;`};
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  &:hover {
    animation: animacao 0.5s ease-in forwards;

    @keyframes animacao {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`;

export const CardLojaImage = styled.img`
  object-fit: contain;
  margin: 10px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  width: 35%;
  margin: 10px;

  @media (max-width: 342px) {
    width: 40%;
  }
`;

export const CardLojaContainerDireita = styled.div`
  padding: 3% 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0.5rem;
  width: 100%;
`;

export const TextoCabecalhoCardLoja = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1rem;
  height: 2rem;
  min-height: 30px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const TextoTipoCardLoja = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  letter-spacing: 0.4px;
  color: ${(props) => props.theme.cores.tipoDaLoja};
`;

export const TextoNumeroDaLoja = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: ${(props) => props.theme.cores.numeroDaLoja};
`;

export const Divisor = styled.div`
  ${(props) => props.headerFixo && `margin-top: 100px;`}
  background-color: ${({ theme }) => theme.cores.textoSimples};
  height: 0.5px;
  width: 100%;
  opacity: 0.2;
`;
