import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";


function Home() {

  const navigate = useNavigate();


  useEffect(() => {

    try {


      //Tratativa temporaria para não listar dashboards para INTEGRADOR e PLATAFORMA

      const sessao = JSON.parse(localStorage.getItem("sessao"));

      if (['PLATAFORMA', 'INTEGRADOR'].includes(sessao?.empresaDTO?.tipoEmpresa)) {
        return;
      }

      //Identificar dashboard padrão para direcionar
      let urlMenuPadrao = '';

      const dashboards = JSON.parse(localStorage.getItem("lista_dashboards"));

      if (dashboards == null || dashboards == '') {
        return;
      }

      dashboards.map((item) => {
        if (item.padrao) {
          urlMenuPadrao = `/dashboard/${item.urlAmigavel}`;
        }
      })

      //Verificar se nenhuma url padrão foi setada, direcionar para a primeira
      if (urlMenuPadrao == '') {
        toast.info('Nenhum Dashboard selecionado como padrão');
        urlMenuPadrao = `/dashboard/${dashboards[0].urlAmigavel}`;
      }

      navigate(urlMenuPadrao);
    } catch (e) {
    }

  }, []);


  function CorpoPagina() {
    return (
      <>
        <Footer caminho={["Dashboard"]} />
      </>
    );
  }


  return CorpoPagina();
}

export default Home;
