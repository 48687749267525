import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { Plano } from "./plano";
import { FormaPagamento } from "./formaPagamento";
import { Fatura } from "./fatura";
import Footer from "../../../components/Footer";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { InformacoesGerais } from "./informacoesGerais";
import { Extras } from "./extras";
import { TabBar } from "../../../components/TabBar";
import { Container, ContainerFundoBranco } from "./styles";
import useTamanhoTela from "../../../utils/useTamanhoTela";
import { useAuth } from "../../../contexts/auth";
import Breadcrumb from "../../../components/Breadcrumb";

export default function DetalhesPlano() {
  const { largura } = useTamanhoTela();
  const { pagamentoNecessario } = useAuth();

  const [mostrarPagina, setMostrarPagina] = useState(
    pagamentoNecessario ? 3 : 0
  );
  const [tipoAplicacaoDefault, setTipoAplicacaoDefault] = useState(null);

  function tratativasAoTrocarAba(tipoAplicacao) {
    if (tipoAplicacao) {
      setTipoAplicacaoDefault(tipoAplicacao);
      setMostrarPagina(1);
    } else {
      setTipoAplicacaoDefault(null);
    }
  }

  // useEffect(() => {
  //   tratativasAoTrocarAba();
  // }, [mostrarPagina]);

  const opcoes = [
    "Informações gerais",
    "Contratos",
    "Forma de pagamento",
    "Fatura",
    "Extras",
  ];

  function getAjusteMargem() {
    if (largura < 768) {
      return " -8px -8px 8px -8px";
    } else {
      return " 0px -24px 24px -24px";
    }
  }

  const renderizarBreadCrumb = () => {
    return (
      <Breadcrumb
        caminho={[
          { titulo: "Gerenciamento de assinatura" },
          { titulo: "Pagamento" },
        ]}
      />
    );
  };

  return (
    <>
      <Container>
        {renderizarBreadCrumb()}
        <ContainerFundoBranco>
          <TabBar
            items={opcoes}
            disabled={pagamentoNecessario}
            indexSelecionado={mostrarPagina}
            setIndexSelecionado={(index) => {
              tratativasAoTrocarAba();
              setMostrarPagina(index);
            }}
            style={{
              margin: getAjusteMargem(),
              width: "unset",
              marginBottom: 32,
            }}
          />

          {mostrarPagina === 0 ? (
            <InformacoesGerais detalharPlano={tratativasAoTrocarAba} />
          ) : mostrarPagina === 1 ? (
            <Plano tipoAplicacaoDefault={tipoAplicacaoDefault} />
          ) : mostrarPagina === 2 ? (
            <FormaPagamento />
          ) : mostrarPagina === 3 ? (
            <Fatura />
          ) : mostrarPagina === 4 ? (
            <Extras tipoAplicacaoDefault={tipoAplicacaoDefault} />
          ) : null}
        </ContainerFundoBranco>
        <Footer
          caminho={["Dados Da Empresa", "Minha Empresa"]}
          icon="gerarIcone"
          gerarIcone={faGear}
        />
      </Container>
    </>
  );
}
