import { Accordion, AccordionDetails, Typography } from "@mui/material";
import styled from "@emotion/styled";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.cores.surfaceBackground1};
  margin: 0px 40px 80px 40px;

  @media (max-width: 768px) {
    margin: 0px 20px 80px 20px;
  }
`;

export const ContainerFundoBranco = styled.div`
  margin-top: 84px;
  width: 100%;
  background-color: ${(props) => props.theme.cores.surface2};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 95px;
  margin-bottom: 146px;

  @media (max-width: 768px) {
    margin-bottom: 50px;
    margin-top: 24px;
  }
`;

export const CabecalhoPagina = styled.div`
  width: 400px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 10px;
  margin-top: 55px;
  border-bottom: 1px solid ${(props) => props.theme.cores.textoContrato};
  margin-bottom: 70px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TituloCabecalhoPagina1 = styled.input`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: ${(props) => props.theme.cores.textoContrato};
  background: transparent;
  border: none;

  &::placeholder {
    color: ${(props) => props.theme.cores.textoContrato};
  }

  &:focus {
    border: none;
    outline: 0;

    &::placeholder {
      color: transparent;
    }
  }

  &:focus {
    border: none;
    outline: 0;

    &::placeholder {
      color: transparent;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }
`;

export const IconeBotao = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;

export const ContainerGlobalSessoes = styled.div`
  display: flex;
  gap: 150px;
  align-items: center;
  margin-bottom: 27px;

  @media (max-width: 768px) {
    gap: 41px;
    flex-direction: column;
    margin-bottom: 22px;
  }
`;

export const TextoGlobalSessoes = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: ${(props) => props.theme.cores.textoContrato};

  @media (max-width: 768px) {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 0.5px;
  }
`;

export const ContainerSessoes = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BoxSessoes = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 8px;
`;

export const TextoBoxSessoes = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: ${(props) => props.theme.cores.textoContrato};

  @media (max-width: 768px) {
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 21px;
    letter-spacing: 0.15px;
  }
`;

export const ContainerSessaoAdicional = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContainerInputSessaoAdicional = styled.div`
  box-sizing: border-box;
  width: 81px;
  border-bottom: 1px solid ${(props) => props.theme.cores.textoContrato};
  padding: 8px 10px;
`;

export const TypographyTitulo = styled(Typography)`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.5px;
  @media (max-width: 768px) {
    width: 100%;
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }
`;

export const InputSessaoAdicional = styled.input`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.cores.textoContrato};
  background: transparent;
  border: none;

  &::placeholder {
    color: ${(props) => props.theme.cores.textoContrato};
  }

  &:focus {
    border: none;
    outline: 0;

    &::placeholder {
      color: transparent;
    }
  }

  @media (max-width: 414px) {
    font-size: 14px;
  }
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  margin: 0;
  margin-bottom: 0;
  background-color: ${(props) => props.theme.adaptativo("brand10", "brand70")};

  overflow: scroll;

  svg {
    color: ${(props) => props.theme.textoContrato};
  }

  &.MuiAccordion-root {
    margin: 0;
  }

  #panel1d-content {
    min-width: 600px;
  }

  .MuiCollapse-root {
    overflow-x: scroll;
  }

  thead {
    display: none;
  }

  tr:nth-child(odd) {
    background-color: ${(props) =>
      props.theme.adaptativo("neutral100", "surface2")} !important;
  }

  tr {
    height: 90px;
  }

  .container-tabela {
    height: unset !important;
  }
`;

export const LinhaClara = styled(AccordionDetails)`
  background: ${(props) => props.theme.cores.surface2};
  display: flex;
  align-items: center;
  height: 86px;
  justify-content: space-between;
  padding-right: 220px;
  gap: 10px;

  @media (max-width: 768px) {
    padding-right: 12px;
  }
`;

export const TextoLinha = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.cores.textoContrato};

  @media (max-width: 414px) {
    font-size: 14px;
  }
`;

export const LinhaEscura = styled(AccordionDetails)`
  background: ${(props) => props.theme.adaptativo("surface3", "neutral10")};
  display: flex;
  align-items: center;
  height: 86px;
  padding-right: 220px;
  gap: 10px;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding-right: 12px;
  }
`;

export const FooterPagina1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const BotaoProximaPagina = styled.button`
  background-color: ${(props) => props.theme.cores.actionActive};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  width: 200px;
  height: 48px;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.cores.actionHover2};
    transition: 0.3s;
  }
`;

export const TextoBotaoProximaPagina = styled.p`
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: ${(props) => props.theme.cores.textAction};
`;

export const BoxCountLinha = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 6px;
`;

export const TextoBoxCountLinha = styled.div`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.adaptativo("neutral40", "neutral100")};
  margin-bottom: 3px;

  @media (max-width: 768px) {
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 21px;
    letter-spacing: 0.15px;
  }
`;

export const FooterPagina2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: center;
    gap: 16px;
  }
`;

export const BotaoPaginaAnterior = styled.button`
  border: 1px solid rgba(0, 100, 149, 1);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  width: 200px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.cores.actionHover};
    > p {
      color: #ffffff;
    }
  }
`;

export const TextoBotaoPaginaAnterior = styled.p`
  color: ${(props) => props.theme.cores.actionActive};
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.25px;

  @media (max-width: 768px) {
    font-size: 17px;
  }
`;

export const ContainerSwitch = styled.div`
  display: flex;
  margin-bottom: 55px;
`;

/* export const ContainerGlobalEmpresas = styled.div`
    padding-top: 30px;
    padding-left: 24px;
    padding-right: 20px;
    padding-bottom: 66px;
    border-radius: 16px;
    margin-bottom: 32px;

    border: 1px solid ${({ theme }) => theme.adaptativo("neutral90", "neutral60")};

`*/

export const ContainerEmpresas = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 32px;
    margin-bottom: 52px;
    align-items: flex-start;
  }
`;

export const TextoContainerEmpresas = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 37px;
  letter-spacing: -0.5px;
  color: ${(props) => props.theme.cores.textoContrato};
`;

export const BotaoContainerEmpresas = styled.button`
  box-sizing: border-box;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  width: 149px;
  height: 48px;
  border: 1px solid rgba(0, 100, 149, 1);
  border-radius: 8px;
  cursor: pointer;
  align-self: flex-end;
  transition: 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.cores.actionHover2};
  }
`;

export const TextoBotaoContainerEmpresas = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: rgba(0, 100, 149, 1);
`;

export const ContainerChip = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Chip = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 25px;
  gap: 10px;
  width: 396px;
  height: 32px;
  border-radius: 99px;
  border: 1px solid ${(props) => props.theme.cores.brand40Default};

  @media (max-width: 1010px) {
    width: 360px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TextoChip = styled.p`
  width: 276px;
  height: 24px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.cores.brand40Default};

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 413px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const FooterPagina3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: center;
    gap: 16px;
  }
`;

export const BotaoFinalizar = styled.button`
  border: none;
  width: 200px;
  height: 48px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(22, 156, 52, 1);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoBotaoFinalizar = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: ${(props) => props.theme.cores.neutral100};

  @media (max-width: 768px) {
    font-size: 17px;
  }
`;

export const ContainerMobileEmpresas = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 24px 16px;
    border: 1px solid
      ${({ theme }) => theme.adaptativo("neutral90", "neutral60")};
    gap: 32px;
    border-radius: 16px;
    margin-bottom: 40px;
  }
`;
