const temaDark = {
  titulo: "dark",
  temaDarkAtivo: true,
  cores: {
    //BRAND
    brand10: "rgba(209, 235, 250, 1)",
    brand20: "rgba(163, 215, 245, 1)",
    brand30: "rgba(106, 188, 236, 1)",
    brand40Default: "rgba(35, 160, 232, 1)",
    brand50: "rgba(27, 125, 181, 1)",
    brand60: "rgba(16, 72, 105, 1)",
    brand70: "rgba(47, 83, 105, 1)",
    brand80: "rgba(5, 31, 46, 1)",
    brand85: "rgba(5, 31, 46, 1)",
    brand90: "rgba(1, 7, 10, 1)",
    brand900: "rgba(215, 81, 0, 1)",
    brand1000: "rgba(19, 124, 185, 1)",

    //NEUTRAL

    neutral100: "rgba(255, 255, 255, 1)",
    neutralBackground99: "rgba(251, 252, 255, 1)",
    neutral95: "rgba(220, 227, 233, 0.99)",
    neutral90: "rgba(192, 199, 205, 1)",
    neutral80: "rgba(194, 199, 207, 1)",
    neutral70: "rgba(165, 172, 178, 1)",
    neutral60: "rgba(138, 146, 151, 1)",
    neutral50: "rgba(113, 120, 125, 1)",
    neutral40: "rgba(88, 95, 100, 1)",
    neutral30: "rgba(54, 68, 77, 1)",
    neutral20: "rgba(64, 72, 76, 1)",
    neutral10: "rgba(33, 36, 38, 1)",
    neutralVariant10: "rgba(33, 36, 38, 1)",
    neutral0: "rgba(0, 0, 0, 1)",
    neutral99: "rgba(33, 36, 38, 1)",

    //SURFACE
    surfaceBackground1: "rgba(33, 36, 38, 1)",
    surfaceBackground2: "rgba(33, 36, 38, 1)",
    surface2: "rgba(51, 51, 51, 1)",
    surface3: "rgba(54, 68, 77, 1)",
    surface4: "rgba(16, 72, 105, 1)",
    surface5: "rgba(51, 51, 51, 1)",
    surface6: "rgba(255, 255, 255, 1)",
    surfaceTableCell: "rgba(33, 36, 38, 1)",
    surface7: "rgba(13, 52, 75, 1)",

    //Primary
    textActionPrimary: "rgba(5, 31, 46, 1)",
    textActionPrimaryAlernative: "rgba(5, 31, 46, 1)",
    surfaceActionActivePrimary: "rgba(35, 160, 232, 1)",
    surfaceActionHoverPrimary: "rgba(144, 205, 255, 1)",
    surfaceActionPressedPrimary: "rgba(203, 230, 255, 1)",

    //Success
    textActionSuccess: "rgba(0, 57, 33, 1)",
    textActionSuccessAlternative: "rgba(255, 255, 255, 1)",
    surfaceActionActiveSuccess: "rgba(65, 224, 152, 1)",
    surfaceActionHoverSuccess: "rgba(209, 255, 238, 1)",
    surfaceActionPressedSuccess: "rgba(0, 57, 33, 1)",

    //Disabled
    textActionDisabled: "rgba(220, 227, 233)",
    surfaceActionDisabled: "rgba(88, 95, 100)",

    //OVERLAY
    overlay: "rgba(1,7,10,0.8)",

    //ACTION
    actionActive: "rgba(35, 160, 232, 1)",
    actionHover: "rgba(144, 205, 255, 1)",
    actionHover2: "rgba(144, 205, 255, 1)",
    actionPressed: "rgba(203, 230, 255, 1)",
    actionDisable: "rgba(88, 95, 100, 1)",

    textAction: "rgba(5, 31, 46, 1)",
    textActionDisable: "rgba(220, 227, 233, 1)",

    //SEMANTIC - ERRO

    semanticDanger1: "rgba(105, 0, 5, 1)",
    semanticDanger2: "rgba(251, 162, 162, 1)",
    semanticDanger3: "rgba(147, 0, 10, 1)",
    semanticDanger4: "rgba(255, 218, 214, 1)",

    //SEMANTIC - SUCESSO

    semanticSuccess3: "rgba(0, 57, 33, 1)",
    semanticSuccess2: "rgba(65, 224, 152, 1)",
    semanticSuccess1: "rgba(0, 82, 50, 1)",
    semanticSuccess4: "rgba(178, 255, 171, 1)",
    semanticSuccess1Antiga: "rgba(22, 156, 52, 1)",
    semanticSuccessBtn: "rgba(65, 224, 152, 1)",

    //SEMANTIC - INFO

    semanticInfo3: "rgba(0, 73, 125, 1)",
    semanticInfo2: "rgba(159, 202, 255, 1)",
    semanticInfo1: "rgba(0, 50, 89, 1)",

    //SEMANTIC - ALERT

    semanticAlert3: "rgba(86, 69, 0, 1)",
    semanticAlert2: "rgba(113, 92, 0, 1)",
    semanticAlert1: "rgba(59, 47, 0, 1)",

    //toastify
    toastifySucessoFundo: "rgba(65, 224, 152, 1)",
    toastifySucessoTexto: "rgba(0, 57, 33, 1)",
    toastifyInfoFundo: "rgba(159, 202, 255, 1)",
    toastifyInfoTexto: "rgba(0, 29, 53, 1)",
    toastifyErrorFundo: "rgba(147, 0, 10, 1)",
    toastifyErrorTexto: "rgba(251, 162, 162, 1)",
    toastifyWarningFundo: "rgba(236, 195, 0, 1)",
    toastifyWarningTexto: "rgba(59, 47, 0, 1)",
    toastifyAlternativeWarningFundo: "rgba(224, 132, 65, 1)",
    toastifyAlternativeWarningTexto: "rgba(57, 27, 0, 1)",

    //Extras

    textoSimples: "rgba(255, 255, 255, 1)",
    textoSimples2: "rgba(255, 255, 255, 1)",
    textoInverso: "rgba(13, 13, 13, 1)",
    brand40neutral100: "rgba(255, 255, 255, 1)",
    neutral40neutral100: "rgba(255, 255, 255, 1)",
    brand70neutral100: "rgba(255, 255, 255, 1)",
    brand80neutral100: "rgba(255, 255, 255, 1)",
    brand10brand80: "rgba(5, 31, 46, 1)",
    semanticSuccess3SemanticSuccess2: "rgba(65, 224, 152, 1)",
    brand10neutral20: "rgba(64, 72, 76, 1)",
    neutral100brand70: "rgba(47, 83, 105, 1)",
    neutral100brand80: "rgba(5, 31, 46, 1)",
    neutral105: "rgba(255, 255, 255, 1)",
    neutral110: "rgba(138, 146, 151, 1)",
    neutral40ActionDisable: "rgba(88, 95, 100, 1)",
    sufacedark3Neutral90: "rgba(54, 68, 77, 1)",
    textoSimples3: "rgba(232, 245, 253, 1)",
    corCardLoja: "rgba(62, 66, 70, 1)",
    numeroDaLoja: "rgba(251, 252, 255, 1)",
    tipoDaLoja: "rgba(194, 199, 207, 1)",
    textoModal: "rgba(255, 255, 255, 1)",
    botaoModal: "rgba(255, 255, 255, 1)",
    backgroundInput: "rgba(255, 255, 255, 0.2)",
    corInput: "rgba(255, 255, 255, 1)",
    corBoxPagina: "rgba(255, 255, 255, 1)",
    backgroundBoxPagina: "rgba(255, 255, 255, 0.2)",
    corHr: "rgba(255, 255, 255, 0.2)",
    corCardRelatorioClientes: "rgba(47, 83, 105, 1)",
    textoContrato: "rgba(255, 255, 255, 1)",
    corCheckbox: "rgba(255, 255, 255, 1)",
    accordionEdicao: "rgba(5, 31, 46, 1)",
    accordionIcon: "rgba(255, 255, 255, 1)",
    corTituloAccordion: "rgba(255, 255, 255, 1)",
    corBGAccordion: "rgba(47, 83, 105, 1)",
    backgroundInputMaterial: "rgba(255, 255, 255, 0.2)",
    transparent: "rgba(0, 0, 0, 0.0)",
    semanticSuccess5: "rgba(209, 255, 238, 1)",
    semanticAlert1Antiga: "rgba(255, 248, 214, 1)",
    borderAccordion: "rgba(255, 255, 255, 0.12)",
    linhaBorda: "rgba(255, 255, 255, 0.25)",
    textoCard: "rgba(192, 199, 205, 1)",
    bordaCard: "rgba(255, 255, 255, 0.20)",
    textoCaminho: "rgba(255, 255, 255, 1)",
    textoPopup: "rgba(192, 199, 205, 1)",
    backgroundTituloModal: "rgba(45, 47, 48, 1)",
    bordaCardMeioPublicidade: "rgba(255,255,255,0.1)",
    iconeEditar: "rgba(0, 29, 54, 1)",
    iconeExclir: "rgba(147, 0, 10, 1)",
    fundoEditar: "rgba(35, 160, 232, 1)",
    fundoExcluir: "rgba(251, 162, 162, 1)",
    visualizarEmpresa: "rgba(35, 161, 232, 1)",
    textSelecionarEmpresa: "rgba(255, 255, 255, 1)",
    imagemPlaceholder: "rgba(71, 71, 71, 1)",
    stroke: "rgba(165, 203, 247, 0.2)",
    bgCheckBoxContainer: "rgba(54, 68, 77, 1)",
    clientSecretBg: "rgba(50, 71, 98, 1)",
    cancelIcon: "rgba(251, 162, 162, 1)",
    stroke: "rgba(165, 203, 247, 0.2)",
    hoverTabela: "rgba(255, 255, 255, 0.2)",
    chipRoxo: "rgba(213, 159, 255, 1)",
    textoChipRoxo: "rgba(40, 0, 92, 1)",
    chipVermelho: "rgba(255, 159, 159, 1)",
    textoChipVermelho: "rgba(92, 0, 6, 1)",
    cancelIcon: "rgba(251, 162, 162, 1)",
    menuCardapioFundo: "rgba(47, 83, 105, 1)",
    menuCardapioFundoTexto: "rgba(255, 255, 255, 1)",
    menuCardapioFundoItemSelecionado: "rgba(144, 202, 249)",
    linhaAzulTabela: "rgba(0, 34, 53, 1)",
    corStatusCinza: "rgba(192, 199, 205, 1)",
    corFonteTextoModalPedidoCompra: "rgba(255, 255, 255, 1)",
    corFonteTextoModalPedidoCompraHover: "rgba(5, 31, 46, 1)",
    corTextoCard: "rgba(255, 255, 255, 1)",
    corFundoCardTotalProdutos: "rgb(16, 64, 92)",
    warning: "rgba(236, 195, 0, 1)",
    corInputDisabled: " rgba(255, 255, 255, 0.7)",
    surfacebg5Dashboard: "rgba(22, 85, 122, 1)",
    backgroundCardDashboard: "rgba(33, 36, 38, 1)"
  },
  adaptativo: function (cor1, cor2) {
    return this.cores[cor2];
  },
  getCorTema: function (light, dark) {
    return dark;
  },
};

export default temaDark;
