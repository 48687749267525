export const PLATAFORMA = "PLATAFORMA";
export const INTEGRADOR = "INTEGRADOR";
export const MATRIZ = "MATRIZ";
export const FILIAL = "FILIAL";
export const FRANQUIA = "FRANQUIA";
export const FRANQUEADO = "FRANQUEADO";


export function getUrlEmpresa(tipoEmpresa)
{
    switch (tipoEmpresa) {
        case PLATAFORMA:
            return "/plataforma";
        case INTEGRADOR:
            return "/integrador";

        case MATRIZ:
        case FILIAL:
        case FRANQUIA:
        case FRANQUEADO:
            return "/consumidor";
    }
}
