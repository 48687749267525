import * as React from "react";
import MaterialCheckbox from "@mui/material/Checkbox";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
  Footer,
  FooterRow,
  FooterCell,
} from "@table-library/react-table-library/table";
import {
  useSort,
  HeaderCellSort,
  SortToggleType,
} from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";

import {
  SelectTypes,
  useRowSelect,
  CellSelect,
  HeaderCellSelect,
} from "@table-library/react-table-library/select";

import {
  AccordionCard,
  BotaoAzulRefatorado,
  BotaoPagina,
  Botoes,
  BotoesIcone,
  CardTabelaPreco,
  ContainerCards,
  ContainerCheckbox,
  ContainerPaginaAtualTextfield,
  ContainerPaginacao,
  ContainerPaginas,
  ContainerTabela,
  ContainerTabelaSecundaria,
  ContainerTextoTabelaSecundaria,
  CustomizacaoCheckbox,
  FiltragemTabela,
  IconeBotaoPage,
  IconeColunas,
  IconsFirstAndLastPage,
  StyledAutocomplete,
  StyledCampoDeSelecao,
  StyledContainerGlobal,
  StyledHr,
  StyledMinus,
  StyledPlus,
  StyledPopupColunas,
  StyledTextFieldAutocomplete,
  StyledTextFieldSelect,
  TextFieldIcon,
  TextoFiltroSemResultado,
  TextoHeaderTabela,
} from "./styles";
import { ReactComponent as ArrowUpDownDark } from "../../assets/icons/ArrowUpDownDark.svg";
import { ReactComponent as ArrowUpDownLight } from "../../assets/icons/ArrowUpDownLight.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  useTree,
  CellTree,
  TreeExpandClickTypes,
} from "@table-library/react-table-library/tree";
import { TemaCustomizadoContext } from "../../contexts/theme";
import { useContext, Fragment } from "react";
import { useTheme as usarTema } from "@emotion/react";
import {
  CampoDeDataSemHora,
  CampoDeInputNumerico,
  CampoDeSelecao,
  CampoDeTexto,
} from "../CampoInput";

import { BotaoAzulVazado } from "../Botao";
import { CheckBox } from "../Checkbox";
import { useState } from "react";
import { MenuItem, Skeleton } from "@mui/material";
import useTamanhoTela from "../../utils/useTamanhoTela";
import { useEffect, useRef } from "react";
import { useTheme } from "@table-library/react-table-library/theme";
import { useLoader } from "../../contexts/loader";
import tornarSlider from "../../utils/tornarSlider";
import { TabelaSimples } from "../TabelaSimples";
import formatarReal from "../../utils/formatarReal.js";
import Carregando from "../../pages/carregando";
import { debounce } from "lodash";
import dayjs from "dayjs";

let textosInputs;
let textoFiltroGeral;
let quantidadePaginacao = null;
let linhaSelecionada = null;
let defaultOpenTree = [];
let mudarValor = {};
const dadosMokadosTabela = {
  "paginas": 3,
  "pagina": 0,
  "colunas": [
    {
      "key": "checkbox",
      "titulo": "Checkbox",
      "esconder": false,
    },
    {
      "key": "descricao",
      "titulo": "Dados Mockados 1 ",
      "esconder": false,
    },
    {
      "key": "idImpressoraPadrao",
      "titulo": "Dados Mockados 2",
    },
    {
      "key": "versao",
      "titulo": "Dados Mockados 3",
    },
  ],
  "nodes": [
    {
      "id": 1031,
      "descricao": "Dados Mockados 11222",
      "idImpressoraPadrao": "Dados Mockados 450",
      "versao": 9,
    },
    {
      "id": 1022,
      "descricao": "Dados Mockados a",
      "idImpressoraPadrao": "Dados Mockados 391",
      "versao": 0,
    },
    {
      "id": 1023,
      "descricao": "Dados Mockados aa",
      "idImpressoraPadrao": "Dados Mockados 391",
      "versao": 0,
    },
    {
      "id": 984,
      "descricao": "Dados Mockados aaa",
      "idImpressoraPadrao": "Dados Mockados 906",
      "versao": 4,
    },
    {
      "id": 29,
      "descricao": "Dados Mockados BARa",
      "idImpressoraPadrao": "Dados Mockados316",
      "versao": 11,
    },
    {
      "id": 736,
      "descricao": "Dados Mockados BARNOTURNO",
      "idImpressoraPadrao": "Dados Mockados 845",
      "versao": 1,
    },
    {
      "id": 118,
      "descricao": "Dados Mockados CAIXA Editado",
      "idImpressoraPadrao": "Dados Mockados 579",
      "versao": 10,
    },
    {
      "id": 980,
      "descricao": "Dados Mockados CAIXASP",
      "idImpressoraPadrao": "Dados Mockados 391",
      "versao": 3,
    },
    {
      "id": 979,
      "descricao": "Dados Mockados COMPUTADOR TESTE",
      "idImpressoraPadrao": "Dados Mockados 1060",
      "versao": 1,
    },
    {
      "id": 30,
      "descricao": "Dados Mockados Cozinha",
      "idImpressoraPadrao": "Dados Mockados 880",
      "versao": 23,
    },
  ],
};

const itensDiasDaSemana = [
  { displayValue: "Selecione", value: null },
  { displayValue: "Segunda-feira", value: "SEGUNDA_FEIRA" },
  { displayValue: "Terça-feira", value: "TERCA_FEIRA" },
  { displayValue: "Quarta-feira", value: "QUARTA_FEIRA" },
  { displayValue: "Quinta-feira", value: "QUINTA_FEIRA" },
  { displayValue: "Sexta-feira", value: "SEXTA_FEIRA" },
  { displayValue: "Sábado", value: "SABADO" },
  { displayValue: "Domingo", value: "DOMINGO" },
];

export function TabelaLib({
  itemUnico = false,
  dadosTabela = undefined,
  variante = {},
  dadosMokados = false,
  porModulo = false,
  textoPorModulo = "",
  linhaValorTela,
}) {
  dadosTabela = dadosMokados ? dadosMokadosTabela : dadosTabela;

  const { carregandoTabela } = useLoader();
  const tema = usarTema();
  const { getTemaDarkAtivo } = useContext(TemaCustomizadoContext);
  const booleanTema = getTemaDarkAtivo();
  const [statusPesquisaColuna, setStatusPesquisaColuna] = useState(
    variante?.filtroColunaVisivel || false
  );
  const [valorInput, setValorInput] = useState("");
  const [valorInputGeral, setValorInputGeral] = useState("");
  const [valorInputColuna, setValorInputColuna] = useState("");
  const { largura } = useTamanhoTela();
  const [diaDaSemana, setDiaDaSemana] = useState("Selecione");
  const [ordenacaoColunas, setOrdenacaoColunas] = useState({});
  const refTabelaScroll = useRef();
  const [quantidadeItensMostrarTabela, setQuantidadeItensMostrarTabela] =
    useState([]);
  const [quantidadeItens, setQuantidadeItens] = useState(
    itemUnico ? "1" : quantidadePaginacao !== null ? quantidadePaginacao : "10"
  );
  const [itemSelecionadoLinha, setItemSelecionadoLinha] = useState(false);
  const [primeiroCarregamento, setPrimeiroCarregamento] = useState(true);
  const [primeiroCarregamentoGlobal, setPrimeiroCarregamentoGlobal] =
    useState(true);
  const [colunasVisiveis, setColunasVisiveis] = useState(
    dadosTabela.colunas?.map((coluna) => coluna.key)
  );
  const [alturaContainerTabela, setAlturaContainerTabela] = useState(
    variante?.alturaContainerTabela || "660px"
  );
  function mudarEstado() {
    setItemSelecionadoLinha((state) => !state);
  }
  const [defaultAllOpenTree, setDefaultAllOpenTree] = useState([]);
  useEffect(() => {
    tornarSlider(refTabelaScroll.current);
  }, [refTabelaScroll]);

  const opcoesSelectStatus = [
    {
      value: "ATIVO",
      label: "Ativo",
    },
    {
      value: "INATIVO",
      label: "Inativo",
    },
    {
      value: "TODOS",
      label: "Todos",
    },
  ];

  function valorSelectStatus(valor) {
    textosInputs = { ...textosInputs, status: valor == "TODOS" ? null : valor };
    setValorInputColuna(textosInputs);
  }

  useEffect(() => {
    if (primeiroCarregamento && variante?.esconderColuna) {
      blurTabela(1800);
    }
  }, [dadosTabela]);

  useEffect(() => {}, [dadosTabela, linhaValorTela]);

  // Ordenação da coluna
  const sort = useSort(
    dadosTabela,
    {
      onChange: onSortChange,
    },
    {
      sortIcon: {
        margin: "10px",
        iconDefault: booleanTema ? <ArrowUpDownDark /> : <ArrowUpDownLight />,
        iconUp: <FontAwesomeIcon icon={faArrowDown} />,
        iconDown: <FontAwesomeIcon icon={faArrowUp} />,
      },
      sortToggleType: SortToggleType.AlternateWithReset,
      isServer: true,
      sortFns: {
        TEXTO: "none",
      },
    }
  );

  function mudarValoresSelect(displayValue, itemsSelect) {
    const item = itemsSelect.find((item) => item.displayValue === displayValue);
    return item ? item.value : null;
  }

  function onSortChange(action, state) {
    let direcao;
    let coluna = action.payload.value.sortKey;

    if (state.reverse) {
      direcao = "DESC";
    } else {
      direcao = "ASC";
    }

    if (state.sortKey === "NONE") {
      coluna = "id";
      direcao = "";
    }

    setOrdenacaoColunas({ direcao, coluna });
  }

  // Tree
  const data = { nodes: dadosTabela };
  const tree = useTree(
    data,
    {
      state: {
        ids: variante?.treesAbertas ? variante?.treesAbertas : [],
      },
      onChange: onTreeChange,
    },
    {
      treeIcon: {
        margin: "10px",
        iconRight: (
          <FontAwesomeIcon
            icon={faAngleRight}
            style={{ color: tema.cores.actionActive }}
          />
        ),
        iconDown: (
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{ color: tema.cores.actionActive }}
          />
        ),
      },
      clickType: TreeExpandClickTypes.ButtonClick,
    }
  );

  function onTreeChange(action, state) {}

  // features

  const gerarBotoes = (pagina, paginas, maxBotoes = 5) => {
    const botao = (e) => (
      <BotaoPagina
        key={e}
        disabled={e === pagina}
        accent="#000000"
        color={e === pagina ? "#CBE6FF" : "transparent"}
        style={{
          color:
            e === pagina ? tema.cores.textoInverso : tema.cores.textoSimples,
          backgroundColor:
            e === pagina ? tema.cores.actionPressed : "transparent",
        }}
        selected={e === pagina}
        onClick={() => {
          pagination.fns.onSetPage(e);
          dadosTabela?.mudarPagina(e, body);
          setValorInput(String(e + 1).padStart(2, "0"));
        }}
      >
        {e + 1}
      </BotaoPagina>
    );

    // Quantos botões serão mostrados
    // No máximo maxBotoes, mas pode ser menos devido a quantidade de páginas
    maxBotoes = largura > 767 ? 5 : 3;
    let quantidadeBotoesMostrados = Math.min(paginas, maxBotoes);
    let metadeMaxBotoes = (maxBotoes - 1) / 2;
    let botaoInicial = 0;
    let ultimoBotao = paginas - 1;

    // Booleano
    let todosBotoesSendoMostrados = paginas === quantidadeBotoesMostrados;

    // Booleano
    let chegandoNosUltimosBotoes = pagina + metadeMaxBotoes > ultimoBotao;

    if (!todosBotoesSendoMostrados) {
      let offset = metadeMaxBotoes;

      if (chegandoNosUltimosBotoes)
        offset = offset + (pagina + metadeMaxBotoes - ultimoBotao);

      botaoInicial = Math.max(0, pagina - offset);
    }

    return Array.from({ length: quantidadeBotoesMostrados }, (_, index) =>
      botao(index + botaoInicial)
    );
  };

  const pagination = usePagination(
    data,
    {
      state: {
        page: itemUnico ? dadosTabela.pagina : 0,
      },
    },
    {
      isServer: true,
    }
  );

  // Esconder colunas
  const [colunasEscondidas, setColunasEscondidas] = useState([]);

  const [colunasKeyEscondidas, setColunasKeyEscondidas] = useState([]);

  const toggleColumn = (column, keyColumn) => {
    if (colunasEscondidas.includes(column)) {
      setColunasEscondidas(colunasEscondidas.filter((v) => v !== column));
    } else {
      setColunasEscondidas(colunasEscondidas.concat(column));
    }

    if (colunasVisiveis.includes(keyColumn)) {
      setColunasVisiveis(colunasVisiveis.filter((v) => v !== keyColumn));
    } else {
      setColunasVisiveis(colunasVisiveis.concat(keyColumn));
    }

    if (colunasKeyEscondidas.includes(keyColumn)) {
      setColunasKeyEscondidas(
        colunasKeyEscondidas.filter((v) => v !== keyColumn)
      );
      textosInputs = { ...textosInputs, [keyColumn]: null };
    } else {
      setColunasKeyEscondidas(colunasKeyEscondidas.concat(keyColumn));
      excluirColunasEscondidas();
    }
  };

  // Funções aleatórias
  function habilitarPesquisaColuna() {
    if (variante.filtroColuna || variante === "completa") {
      setStatusPesquisaColuna(!statusPesquisaColuna);
    }
  }

  useEffect(() => {
    (variante?.filtroGeral && pegarValorFiltroGeral(valorInputGeral)) ||
      (variante === "completa" && pegarValorFiltroGeral(valorInputGeral));
  }, [valorInputGeral]);

  function trocarPaginaInput(pagina) {
    const paginaFormatada = pagina - 1;
    pagination.fns.onSetPage(paginaFormatada);
    dadosTabela?.mudarPagina(paginaFormatada, body);
    setValorInput(pagina);
  }

  useEffect(() => {
    if (variante?.mudarPaginaCashback) {
      pagination.fns.onSetPage(dadosTabela?.pagina);
    }
    pagination.fns.onSetPage(dadosTabela?.pagina);
  }, [dadosTabela?.pagina]);

  function trocarQuantidadeItensTabela(valor) {
    setQuantidadeItens(valor);
    pagination.fns.onSetPage(0);
    quantidadePaginacao = valor;
  }

  useEffect(() => {
    if (variante?.footerMonetarioAnual) {
      setIds([dadosTabela?.nodes[dadosTabela?.nodes?.length - 1]?.id]);
    }
  }, [dadosTabela?.nodes]);

  useEffect(() => {
    if (variante?.mudarPaginacaoCheckbox) {
      pagination.fns.onSetPage(dadosTabela?.pagina);
    }
  }, [dadosTabela?.pagina]);
  //Select
  const select = useRowSelect(
    dadosTabela,
    {
      state: {
        ids: dadosTabela?.idsCheckboxSelecionados
          ? dadosTabela?.idsCheckboxSelecionados?.map((item) => Number(item))
          : [],
      },
      onChange: onSelectChange,
    },
    {
      rowSelect: variante?.checkboxSelecionarApenasUm
        ? SelectTypes.SingleSelect
        : SelectTypes.MultiSelect,
      buttonSelect: variante?.checkboxSelecionarApenasUm
        ? SelectTypes.SingleSelect
        : SelectTypes.MultiSelect,
    }
  );

  const [conteudoInputsSelecionados, setConteudoInputsSelecionados] = useState(
    []
  );

  function onSelectChange(action, state) {
    if (variante?.checkboxSelecionarApenasUm) {
      setConteudoInputsSelecionados(
        dadosTabela.nodes.filter((item) => state.id == item.id)
      );
    } else {
      setConteudoInputsSelecionados(
        dadosTabela.nodes.filter((item) => state.ids.includes(item.id))
      );
    }
  }

  // Customização do tamanho das colunas
  const temaTabela = useTheme({
    Table: `
    --data-table-library_grid-template-columns: ${
      dadosTabela.gridColuna
        ? dadosTabela?.gridColuna
        : `repeat(${dadosTabela.colunas?.length}, minmax(200px, 1fr))`
    }
    `,
  });

  // Expansão de linha
  const [ids, setIds] = useState([]);

  const handleExpand = (item) => {
    if (variante?.footerMonetarioAnual) {
      return;
    }

    if (dadosTabela.itensTabelaSecundaria)
      if (!dadosTabela.itensTabelaSecundaria) {
        return;
      }

    if (ids.includes(item.id)) {
      setIds(ids.filter((id) => id !== item.id));
    } else if (item.listaResgates !== null) {
      setIds(ids.concat(item.id));
    }
  };

  useEffect(() => {
    if (variante?.footerMonetarioAnual) {
      setIds([dadosTabela?.nodes[dadosTabela?.nodes?.length - 1]?.id]);
    }
  }, [dadosTabela?.nodes]);
  // Pegar valor dos inputs

  function pegarValorFiltroGeral(texto = "") {
    textoFiltroGeral = texto || null;
    resetarCamposFiltroNull();
  }

  function resetarCamposFiltroNull() {
    return (textosInputs = colunasVisiveis
      .filter((nomeColuna) => nomeColuna !== "Ações")
      .reduce((obj, coluna) => {
        obj[coluna] = null;
        return obj;
      }, {}));
  }

  function excluirColunasEscondidas() {
    colunasKeyEscondidas.map((coluna) => {
      return delete textosInputs[coluna];
    });
  }

  function pegarValorFiltroColuna(textoColuna, tituloColuna) {
    excluirColunasEscondidas();

    if (!tituloColuna) {
      return;
    }
    textosInputs = { ...textosInputs, [tituloColuna]: textoColuna || null };
    setValorInputColuna(textosInputs);
  }

  // const paginacao = {
  //   "pagina": pagination.state.page,
  //   "paginas": dadosTabela.paginas,
  // };

  useEffect(() => {
    pegarValorFiltroColuna();
  }, [colunasKeyEscondidas]);

  useEffect(() => {
    resetarCamposFiltroNull();
    itemUnico
      ? setQuantidadeItensMostrarTabela(["1"])
      : setQuantidadeItensMostrarTabela(["10", "25", "50", "75", "100"]);
  }, []);

  function blurTabela(timer = 800) {
    setPrimeiroCarregamento(true);
    document
      .getElementsByClassName("containerTabelaLib")[0]
      .classList.add("blurTabela");

    setTimeout(() => {
      try {
        document
          .getElementsByClassName("containerTabelaLib")[0]
          .classList.remove("blurTabela");
        setPrimeiroCarregamento(false);
      } catch (e) {}
    }, timer);
  }

  function formatarData(data) {
    const partes = data.split("/");

    const dataFormatada = partes[2] + "-" + partes[1] + "-" + partes[0];

    return dataFormatada;
  }
  let body = {
    // "paginacao": paginacao,
    "data": textosInputs?.data ? formatarData(textosInputs.data) : null,
    "diaDaSemana": mudarValoresSelect(diaDaSemana, itensDiasDaSemana),
    "ordenacao": ordenacaoColunas,
    "conteudoBody": {
      "palavraChave": textoFiltroGeral,
      "palavraChaveEstado": valorInputGeral,
      "colunasValores": textosInputs,
      "valoresSelect": valorInputColuna,
    },
    "valoresInput": conteudoInputsSelecionados,
    "linhasPorPagina": quantidadeItens,
    "colunasVisiveisKey": colunasVisiveis,
  };

  useEffect(() => {
    setPrimeiroCarregamentoGlobal(false);
    if (Object.values(dadosTabela).length > 0) {
      if (dadosTabela?.valoresColuna) {
        dadosTabela?.valoresColuna(body);
      }
    }
  }, [
    textoFiltroGeral,
    textosInputs,
    ordenacaoColunas,
    valorInputGeral,
    colunasKeyEscondidas,
    conteudoInputsSelecionados,
    quantidadeItens,
    diaDaSemana,
    valorInputColuna,
  ]);

  useEffect(() => {
    if (variante?.tabelaSecundariaSobDemanda && dadosTabela?.buscarSobDemanda)
      dadosTabela?.buscarSobDemanda(ids, dadosTabela);
  }, [ids]);

  return (
    <StyledContainerGlobal hoverLinhas={variante?.hoverLinhas}>
      {(variante?.containerFiltroGeral ||
        variante === "completa" ||
        variante?.filtroColunaVisivel ||
        variante?.filtroColuna) && (
        <FiltragemTabela
          style={{
            justifyContent:
              variante.filtroGeral === false || undefined
                ? "end"
                : "space-between",
          }}
        >
          {(variante?.filtroGeral || variante === "completa") && (
            <TextFieldIcon>
              <CampoDeTexto
                style={{
                  width: largura >= 840 ? 292 : "100%",
                  height: "56px",
                }}
                // value={valorInputGeral}
                onChange={debounce(
                  (e) => setValorInputGeral(e.target.value),
                  300
                )}
                placeholder={
                  variante?.filtroGeralPlaceHolder
                    ? variante?.filtroGeralPlaceHolder
                    : "Filtrar na tabela"
                }
                sx={{
                  "& .MuiInputBase-root": {
                    padding: "0 5px",
                    margin: 0,
                  },
                  ".MuiSelect-select": {
                    marginLeft: "5px",
                  },
                  ".MuiInputBase-input": {
                    padding: "17px",
                    fontSize: "15px",
                  },
                }}
              />
            </TextFieldIcon>
          )}

          <BotoesIcone
            larguraTotal={
              !variante?.filtroColuna &&
              !variante?.esconderColuna &&
              variante?.pedidoDeCompra
            }
          >
            {variante?.botoesExtrasEsquerda && variante?.botoesExtrasEsquerda}
            <Botoes>
              {(variante?.filtroColuna || variante === "completa") && (
                <BotaoAzulVazado
                  onClick={() => {
                    resetarCamposFiltroNull();
                    habilitarPesquisaColuna();
                  }}
                  className="btn-pesquisar-tabela"
                  texto="Pesquisar campos"
                  sublinhado={true}
                />
              )}

              {(variante?.esconderColuna || variante === "completa") && (
                <StyledPopupColunas
                  trigger={() => (
                    <BotaoAzulRefatorado>
                      <IconeColunas color={tema.cores.brand85} weight="fill" />
                      Colunas
                    </BotaoAzulRefatorado>
                  )}
                  position="bottom center"
                  closeOnDocumentClick
                >
                  <ContainerCheckbox>
                    {dadosTabela.colunas &&
                      dadosTabela.colunas.map((coluna, index) => {
                        if (!coluna.titulo) {
                          return;
                        }
                        return (
                          <Fragment key={coluna + index + "key"}>
                            <CheckBox
                              desabilitado={
                                coluna.esconder === false ||
                                primeiroCarregamento
                              }
                              corTexto={tema.cores.textoPopup}
                              texto={coluna.titulo}
                              onClick={() => {
                                blurTabela(900);
                                toggleColumn(coluna.titulo, coluna.key);
                              }}
                              tamanho="SM"
                              valor={
                                !colunasEscondidas.includes(coluna?.titulo)
                              }
                              checked={
                                !colunasEscondidas.includes(coluna?.titulo)
                              }
                              id={index}
                            />
                            <StyledHr />
                          </Fragment>
                        );
                      })}
                  </ContainerCheckbox>
                </StyledPopupColunas>
              )}
            </Botoes>
            {variante?.botoesExtrasDireita && variante?.botoesExtrasDireita}
          </BotoesIcone>
        </FiltragemTabela>
      )}

      <ContainerTabela
        className={`containerTabelaLib ${
          variante?.esconderColuna && "blurTabela"
        }`}
        style={{
          height: dadosTabela.nodes?.length > 4 ? alturaContainerTabela : "",
        }}
      >
        {((primeiroCarregamento && variante?.esconderColuna) ||
          variante?.loadingBodyTabela) && <Carregando />}

        <Table
          ref={refTabelaScroll}
          data={dadosTabela}
          sort={sort}
          theme={temaTabela}
          layout={{
            custom: true,
            fixedHeader: true,
            // horizontalScroll: true,
          }}
          tree={
            (variante === "accordionTabela" || variante?.treeTabela) && tree
          }
          pagination={pagination}
          select={dadosTabela.colunas[0].key === "checkbox" && select}
        >
          {(tableList) => (
            <>
              <Header>
                <HeaderRow>
                  {dadosTabela &&
                    dadosTabela?.colunas.map((coluna, index) => {
                      return (
                        <Fragment key={coluna + index + "key2"}>
                          {coluna.key === "checkbox" &&
                          !variante?.checkboxSelecionarApenasUm ? (
                            <HeaderCell
                              key={coluna.key + index}
                              hide={colunasEscondidas.includes(coluna.titulo)}
                              style={{ alignItems: "flex-start" }}
                            >
                              <MaterialCheckbox
                                inputProps={{ "aria-label": "select all" }}
                                size="medium"
                                checked={select.state.all}
                                indeterminate={
                                  !select.state.all && !select.state.none
                                }
                                onChange={select.fns.onToggleAll}
                              />
                            </HeaderCell>
                          ) : coluna.key === "ativo" ||
                            (coluna.key === "status" &&
                              !variante?.consultasRapidas) ? (
                            <HeaderCell
                              key={coluna.key + index}
                              hide={colunasEscondidas.includes(coluna.titulo)}
                              style={{
                                alignItems: variante?.statusAlinhado
                                  ? "none"
                                  : "center",
                              }}
                            >
                              {coluna.titulo}
                              <div>
                                {(variante === "completa" ||
                                  variante?.filtroColuna ||
                                  variante?.filtroColunaVisivel) &&
                                statusPesquisaColuna &&
                                coluna?.campoDeTexto !== false &&
                                !colunasEscondidas.includes(coluna.titulo) ? (
                                  <StyledTextFieldSelect
                                    select
                                    variant="standard"
                                    onChange={(e) =>
                                      valorSelectStatus(e.target.value)
                                    }
                                  >
                                    {opcoesSelectStatus.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </StyledTextFieldSelect>
                                ) : null}
                              </div>
                            </HeaderCell>
                          ) : statusPesquisaColuna && coluna.titulo ? (
                            <HeaderCell
                              key={coluna.key + index}
                              resize={coluna.resize}
                              hide={colunasEscondidas.includes(coluna.titulo)}
                            >
                              {coluna.titulo}
                              {variante?.diaDaSemana && coluna.diaDaSemana && (
                                <TextFieldIcon>
                                  <CampoDeSelecao
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        padding: "0 5px",
                                        margin: 0,
                                      },
                                      ".MuiSelect-select": {
                                        marginLeft: "5px",
                                      },
                                      ".MuiInputBase-input": {
                                        padding: "12px",
                                        fontSize: "15px",
                                      },
                                    }}
                                    style={{
                                      marginTop: "10px",
                                      marginRight: "10px",
                                    }}
                                    onChange={(e) =>
                                      setDiaDaSemana(e.target.value)
                                    }
                                    campoVazio={false}
                                    valor={diaDaSemana}
                                    items={itensDiasDaSemana.map(
                                      (item) => item.displayValue
                                    )}
                                  />
                                </TextFieldIcon>
                              )}
                              {(variante === "completa" ||
                                variante?.filtroColuna ||
                                variante?.filtroColunaVisivel) &&
                              statusPesquisaColuna &&
                              !coluna.diaDaSemana &&
                              coluna?.campoDeTexto !== false &&
                              !colunasEscondidas.includes(coluna.titulo) ? (
                                <TextFieldIcon>
                                  {coluna.somenteNumeros ? (
                                    <CampoDeInputNumerico
                                      style={{ marginTop: "10px" }}
                                      sx={{
                                        "& .MuiInputBase-root": {
                                          padding: "0 5px",
                                          margin: 0,
                                        },
                                        ".MuiSelect-select": {
                                          marginLeft: "5px",
                                        },
                                        ".MuiInputBase-input": {
                                          padding: "17px!important",
                                          fontSize: "15px",
                                        },
                                      }}
                                      fundoTransparente
                                      placeholder="Digite aqui"
                                      permitirZeroAEsquerda={true}
                                      value={textosInputs?.[coluna.key]}
                                      onChange={(valor) =>
                                        coluna.key !== undefined &&
                                        pegarValorFiltroColuna(
                                          valor,
                                          coluna.key
                                        )
                                      }
                                    />
                                  ) : coluna.autocomplete ? (
                                    <StyledAutocomplete
                                      style={{ marginTop: "10px" }}
                                      disablePortal
                                      onChange={(e, value) => {
                                        textosInputs[coluna.key];
                                        coluna.key !== undefined &&
                                          pegarValorFiltroColuna(
                                            value,
                                            coluna.key
                                          );
                                      }}
                                      options={
                                        dadosTabela.dadosAutocomplete?.length
                                          ? dadosTabela.dadosAutocomplete?.map(
                                              (item) => item.descricao
                                            )
                                          : []
                                      }
                                      renderInput={(params) => (
                                        <StyledTextFieldAutocomplete
                                          {...params}
                                          placeholder="Digite aqui"
                                          label=""
                                        />
                                      )}
                                    />
                                  ) : coluna.campoDeData ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 4,
                                      }}
                                    >
                                      <CampoDeDataSemHora
                                        titulo={"Data Inicial"}
                                        sx={{
                                          ".MuiInputAdornment-root": {
                                            "margin-right": "-12px",
                                          },
                                          "svg": {
                                            "margin-left": "0px",
                                          },
                                        }}
                                        value={
                                          textosInputs[coluna.key]?.dataInicial
                                            ? dayjs(
                                                textosInputs[coluna.key]
                                                  ?.dataInicial
                                              )
                                            : null
                                        }
                                        onChange={(e) =>
                                          coluna.key !== undefined &&
                                          pegarValorFiltroColuna(
                                            {
                                              ...textosInputs[coluna.key],
                                              dataInicial: e,
                                            },
                                            coluna.key
                                          )
                                        }
                                        onAccept={(e) =>
                                          coluna.key !== undefined &&
                                          pegarValorFiltroColuna(
                                            {
                                              ...textosInputs[coluna.key],
                                              dataInicial: e,
                                            },
                                            coluna.key
                                          )
                                        }
                                      />
                                      <CampoDeDataSemHora
                                        titulo={"Data Final"}
                                        sx={{
                                          ".MuiInputAdornment-root": {
                                            "margin-right": "-12px",
                                          },
                                          "svg": {
                                            "margin-left": "0px",
                                          },
                                        }}
                                        value={
                                          textosInputs[coluna.key]?.dataFinal
                                            ? dayjs(
                                                textosInputs[coluna.key]
                                                  ?.dataFinal
                                              )
                                            : null
                                        }
                                        onChange={(e) =>
                                          coluna.key !== undefined &&
                                          pegarValorFiltroColuna(
                                            {
                                              ...textosInputs[coluna.key],
                                              dataFinal: e,
                                            },
                                            coluna.key
                                          )
                                        }
                                        onAccept={(e) =>
                                          coluna.key !== undefined &&
                                          pegarValorFiltroColuna(
                                            {
                                              ...textosInputs[coluna.key],
                                              dataFinal: e,
                                            },
                                            coluna.key
                                          )
                                        }
                                      />
                                    </div>
                                  ) : coluna.checkbox ? (
                                    <CustomizacaoCheckbox>
                                      {coluna.checkboxTitulo}
                                      <CheckBox
                                        valor={
                                          !coluna.checkboxValorInverso
                                            ? textosInputs[coluna.key]
                                              ? false
                                              : true
                                            : textosInputs[coluna.key]
                                            ? true
                                            : false
                                        }
                                        onClick={(e) => {
                                          coluna.key !== undefined &&
                                            // pegarValorFiltroColuna(
                                            //   coluna.checkboxValorInverso
                                            //     ? textosInputs[coluna.key]
                                            //       ? false
                                            //       : true
                                            //     : textosInputs[coluna.key]
                                            //     ? true
                                            //     : false,
                                            //   coluna.key
                                            // );
                                            pegarValorFiltroColuna(
                                              textosInputs[coluna.key]
                                                ? false
                                                : true,
                                              coluna.key
                                            );
                                        }}
                                      />
                                    </CustomizacaoCheckbox>
                                  ) : (
                                    <CampoDeTexto
                                      style={{ marginTop: "10px" }}
                                      sx={{
                                        "& .MuiInputBase-root": {
                                          padding: "0 5px",
                                          margin: 0,
                                        },
                                        ".MuiSelect-select": {
                                          marginLeft: "5px",
                                        },
                                        ".MuiInputBase-input": {
                                          padding: "17px",
                                          fontSize: "15px",
                                        },
                                      }}
                                      fundoTransparente
                                      placeholder="Digite aqui"
                                      value={textosInputs[coluna.key]}
                                      onChange={(e) =>
                                        coluna.key !== undefined &&
                                        pegarValorFiltroColuna(
                                          e.target.value,
                                          coluna.key
                                        )
                                      }
                                    />
                                  )}
                                </TextFieldIcon>
                              ) : null}
                            </HeaderCell>
                          ) : coluna.ordenacao === false ||
                            variante?.ordenacao === false ? (
                            <HeaderCell
                              key={coluna.titulo + index}
                              resize={coluna.resize}
                              hide={colunasEscondidas.includes(coluna.titulo)}
                              style={{
                                background: porModulo && index > 4 && "#1B7DB5",
                                color:
                                  porModulo &&
                                  index > 4 &&
                                  tema.cores.neutral100,
                              }}
                            >
                              {index === 0 && porModulo ? (
                                <div style={{ marginBottom: 24 }}>
                                  <TextoHeaderTabela>Dia</TextoHeaderTabela>
                                </div>
                              ) : index === 5 && porModulo ? (
                                <div style={{ marginBottom: 24 }}>
                                  <TextoHeaderTabela
                                    style={{ color: tema.cores.neutral100 }}
                                  >
                                    {textoPorModulo}
                                  </TextoHeaderTabela>
                                </div>
                              ) : porModulo ? (
                                <div style={{ marginBottom: 64 }}></div>
                              ) : null}

                              {coluna.titulo !== "Checkbox" && coluna.titulo}
                              {(variante === "completa" ||
                                variante?.filtroColuna) &&
                              statusPesquisaColuna &&
                              !coluna.diaDaSemana &&
                              coluna?.campoDeTexto !== false &&
                              !colunasEscondidas.includes(coluna.titulo) ? (
                                <TextFieldIcon>
                                  <CampoDeTexto
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        padding: "0 5px",
                                        margin: 0,
                                      },
                                      ".MuiSelect-select": {
                                        marginLeft: "5px",
                                      },
                                      ".MuiInputBase-input": {
                                        padding: "17px",
                                        fontSize: "15px",
                                      },
                                    }}
                                    fundoTransparente
                                    placeholder="Digite aqui"
                                    value={textosInputs[coluna.key]}
                                    key={index}
                                    onChange={(e) =>
                                      coluna.key !== undefined &&
                                      pegarValorFiltroColuna(
                                        e.target.value,
                                        coluna.key
                                      )
                                    }
                                  />
                                </TextFieldIcon>
                              ) : null}
                            </HeaderCell>
                          ) : (
                            <HeaderCellSort
                              key={coluna.titulo + index}
                              sortKey={coluna.key}
                              resize={coluna.resize}
                              hide={colunasEscondidas.includes(coluna.titulo)}
                            >
                              {coluna.titulo}
                              {variante === "completa" &&
                              statusPesquisaColuna ? (
                                <TextFieldIcon>
                                  <CampoDeTexto
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        padding: "0 5px",
                                        margin: 0,
                                      },
                                      ".MuiSelect-select": {
                                        marginLeft: "5px",
                                      },
                                      ".MuiInputBase-input": {
                                        padding: "17px",
                                        fontSize: "15px",
                                      },
                                    }}
                                    fundoTransparente
                                    onChange={(e) =>
                                      pegarValorFiltroColuna(
                                        e.target.value,
                                        coluna.key
                                      )
                                    }
                                  />
                                </TextFieldIcon>
                              ) : null}
                            </HeaderCellSort>
                          )}
                        </Fragment>
                      );
                    })}
                </HeaderRow>
              </Header>

              <Body>
                {tableList.map((item, index) => (
                  <>
                    <Row
                      key={item.id ? item.id : index}
                      item={item}
                      onClick={() => {
                        mudarValor = dadosTabela.nodes[index]?.parametro;

                        (item?.itensTabelaSecundaria !== null ||
                          item?.itensTabelaSecundariaCards !== null) &&
                          handleExpand(item);

                        variante?.treeTabela
                          ? dadosTabela.eventoCliqueLinha &&
                            dadosTabela.eventoCliqueLinha(linhaSelecionada)
                          : dadosTabela.eventoCliqueLinha &&
                            dadosTabela.eventoCliqueLinha(
                              dadosTabela.nodes[index].parametro
                            );
                        // dadosTabela.eventoCliqueLinha &&
                        //   dadosTabela.eventoCliqueLinha(
                        //     dadosTabela.nodes[index].parametro
                        //   );

                        if (linhaSelecionada?.id !== item.id) {
                          linhaSelecionada = item;
                        } else {
                          linhaSelecionada = null;
                        }
                      }}
                      style={{
                        cursor:
                          (dadosTabela?.colunasTabelaSecundaria?.length ||
                            dadosTabela.eventoCliqueLinha) &&
                          (variante.accordionTabela ||
                            variante.accordionCards ||
                            dadosTabela.eventoCliqueLinha) &&
                          (item.itensTabelaSecundaria ||
                            dadosTabela.eventoCliqueLinha)
                            ? "pointer"
                            : "",
                        backgroundColor:
                          (variante?.hoverLinhas &&
                            variante?.treeTabela &&
                            linhaSelecionada?.id == item.id) ||
                          (variante?.treeTabela &&
                            linhaValorTela?.id == item.id)
                            ? tema.cores.hoverTabela
                            : null,
                      }}
                    >
                      {dadosTabela &&
                        dadosTabela.colunas.map((celula, index) => {
                          return celula.titulo === "Checkbox" ? (
                            <Cell
                              key={celula.titulo + index + "key"}
                              hide={colunasEscondidas.includes(celula.titulo)}
                            >
                              <MaterialCheckbox
                                inputProps={{ "aria-label": "select item" }}
                                size="medium"
                                checked={select.state.ids.includes(item.id)}
                                onChange={() =>
                                  select.fns.onToggleById(item.id)
                                }
                              />
                            </Cell>
                          ) : (variante === "accordionTabela" ||
                              variante?.treeTabela) &&
                            index === 0 ? (
                            <CellTree
                              key={celula.titulo + index + "key"}
                              item={item}
                              hide={colunasEscondidas.includes(celula.titulo)}
                            >
                              {item[celula.key]}
                            </CellTree>
                          ) : variante === "accordionTabela" ? (
                            <Cell
                              key={celula.titulo + index + "key"}
                              hide={colunasEscondidas.includes(celula.titulo)}
                            >
                              {item[celula.key]}
                            </Cell>
                          ) : celula.titulo === "" &&
                            !variante.tabelaSecundariaSobDemanda ? (
                            <Cell
                              key={celula.titulo + index + "key"}
                              hide={colunasEscondidas.includes(celula.titulo)}
                            >
                              {carregandoTabela ? (
                                <Skeleton
                                  sx={{
                                    height: "18.67px",
                                    transform: "none",
                                  }}
                                  animation="wave"
                                />
                              ) : (
                                <>
                                  {(dadosTabela?.itensTabelaSecundaria
                                    ?.length ||
                                    dadosTabela?.itensTabelaSecundariaCards
                                      ?.length) &&
                                    (variante.accordionTabela ||
                                      variante.accordionCards) &&
                                    ids.includes(item.id)}
                                  {(dadosTabela?.colunasTabelaSecundaria
                                    ?.length ||
                                    dadosTabela?.colunasTabelaSecundariaCards
                                      ?.length) &&
                                  (variante.accordionTabela ||
                                    variante.accordionCards) &&
                                  ids.includes(item.id) &&
                                  (item.itensTabelaSecundaria ||
                                    item.itensTabelaSecundariaCards) ? (
                                    <StyledMinus weight="bold" />
                                  ) : item.itensTabelaSecundaria ||
                                    item.itensTabelaSecundariaCards ? (
                                    <StyledPlus weight="bold" />
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </Cell>
                          ) : celula.titulo === "" &&
                            variante.tabelaSecundariaSobDemanda ? (
                            <Cell
                              key={celula.titulo + index + "key"}
                              hide={colunasEscondidas.includes(celula.titulo)}
                            >
                              {carregandoTabela ? (
                                <Skeleton
                                  sx={{
                                    height: "18.67px",
                                    transform: "none",
                                  }}
                                  animation="wave"
                                />
                              ) : (
                                <>
                                  {ids.includes(item.id) ? (
                                    <StyledMinus weight="bold" />
                                  ) : (
                                    <StyledPlus weight="bold" />
                                  )}
                                </>
                              )}
                            </Cell>
                          ) : (
                            <>
                              <Cell
                                hide={colunasEscondidas.includes(celula.titulo)}
                              >
                                {carregandoTabela ? (
                                  <Skeleton
                                    sx={{
                                      height: "18.67px",
                                      transform: "none",
                                    }}
                                    animation="wave"
                                  />
                                ) : (
                                  item[celula.key]
                                )}
                              </Cell>
                            </>
                          );
                        })}
                    </Row>
                    {ids.includes(item.id) &&
                      variante?.accordionTabela &&
                      variante.tabelaSecundariaSobDemanda && (
                        <div
                          style={{
                            display: "flex",
                            gridColumn: "1 / -1",
                          }}
                        >
                          <ContainerTabelaSecundaria style={{ flex: "1" }}>
                            <ContainerTextoTabelaSecundaria>
                              {variante?.textoTabelaSecundaria}
                            </ContainerTextoTabelaSecundaria>
                            <TabelaSimples
                              dados={dadosTabela}
                              item={
                                dadosTabela.tabelaSecundariaSobDemanda[item.id]
                              }
                            />
                          </ContainerTabelaSecundaria>
                        </div>
                      )}
                    {ids.includes(item.id) &&
                      variante?.accordionTabela &&
                      item?.itensTabelaSecundaria?.length > 0 &&
                      !variante.tabelaSecundariaSobDemanda && (
                        <div
                          style={{
                            display: "flex",
                            gridColumn: "1 / -1",
                          }}
                        >
                          <ContainerTabelaSecundaria style={{ flex: "1" }}>
                            <ContainerTextoTabelaSecundaria>
                              {variante?.textoTabelaSecundaria}
                            </ContainerTextoTabelaSecundaria>
                            <TabelaSimples dados={dadosTabela} item={item} />
                          </ContainerTabelaSecundaria>
                        </div>
                      )}
                    {ids.includes(item.id) &&
                      variante?.accordionCards &&
                      item?.itensTabelaSecundariaCards?.length > 0 && (
                        <AccordionCard
                          style={{ display: "flex", gridColumn: "1 / -1" }}
                        >
                          <div style={{ flex: "1" }}>
                            <h1>Detalhe por tabela de preço</h1>
                            <ContainerCards>
                              {item?.itensTabelaSecundariaCards?.map(
                                (content) => {
                                  return (
                                    <CardTabelaPreco>
                                      {dadosTabela?.colunasTabelaSecundariaCards.map(
                                        (coluna) => {
                                          return (
                                            <div>
                                              {coluna.key === "precoVenda" ? (
                                                <p>
                                                  {formatarReal(
                                                    content[coluna.key]
                                                  )}
                                                </p>
                                              ) : (
                                                <p>{content[coluna.key]}</p>
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </CardTabelaPreco>
                                  );
                                }
                              )}
                            </ContainerCards>
                          </div>
                        </AccordionCard>
                      )}
                  </>
                ))}
              </Body>

              <Footer>
                <FooterRow>
                  {dadosTabela.dadosFooter &&
                    dadosTabela.dadosFooter.map((item, index) => {
                      return (
                        <FooterCell key={item + index + "key"}>
                          {carregandoTabela ? (
                            <Skeleton
                              sx={{
                                height: "18.67px",
                                transform: "none",
                              }}
                              animation="wave"
                            />
                          ) : (
                            item
                          )}
                        </FooterCell>
                      );
                    })}
                </FooterRow>
              </Footer>
            </>
          )}
        </Table>
      </ContainerTabela>
      {!dadosTabela.nodes.length && (
        <TextoFiltroSemResultado>
          {variante?.carteiraDigital
            ? "Desculpe, não foi possível encontrar nenhuma carteira digital vinculada."
            : variante?.vinculoEstacao
            ? "Desculpe, não foi possível encontrar nenhuma estação vinculada."
            : "Desculpe, nenhum resultado encontrado."}
        </TextoFiltroSemResultado>
      )}

      {dadosTabela.paginas ? (
        <ContainerPaginacao>
          {largura <= 768 || (
            <ContainerPaginaAtualTextfield>
              {dadosTabela.totalElementos && (
                <>
                  <span>Itens por página</span>

                  <StyledCampoDeSelecao
                    campoVazio={false}
                    disabled={itemUnico}
                    valor={quantidadeItens}
                    items={quantidadeItensMostrarTabela}
                    style={{
                      width: 97,
                      margin: 0,
                      fontSize: 15,
                      textAlign: "center",
                      height: "33px",
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        padding: "0 5px",
                        margin: 0,
                        marginTop: "20px",
                        height: "33px",
                      },
                      ".MuiInputBase-input": {
                        fontSize: "15px",
                      },
                      "& .MuiFormControl-root": {
                        height: "33px",
                      },
                      "& .MuiSelect-select": {
                        paddingBottom: "10px",
                      },
                      "& .css-17idfle-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus":
                        {
                          backgroundColor: "transparent",
                        },
                    }}
                    onChange={(e) =>
                      trocarQuantidadeItensTabela(e.target.value)
                    }
                  />
                </>
              )}
            </ContainerPaginaAtualTextfield>
          )}

          <ContainerPaginas>
            <IconeBotaoPage disabled={pagination.state.page === 0}>
              <IconsFirstAndLastPage
                className="material-symbols-rounded"
                onClick={() => {
                  if (pagination.state.page !== 0) {
                    pagination.fns.onSetPage(0);
                    dadosTabela?.mudarPagina(0, body);
                    setValorInput((state) =>
                      String(Number(state) - 1).padStart(2, "0")
                    );
                  }
                }}
              >
                first_page
              </IconsFirstAndLastPage>
            </IconeBotaoPage>
            <IconeBotaoPage disabled={pagination.state.page === 0}>
              <FontAwesomeIcon
                icon={faAngleLeft}
                color={tema.cores.actionActive}
                style={{ width: 24, height: 24 }}
                onClick={() => {
                  if (pagination.state.page >= 1) {
                    pagination.fns.onSetPage(pagination.state.page - 1);
                    dadosTabela?.mudarPagina(dadosTabela.pagina - 1, body);
                    setValorInput((state) =>
                      String(Number(state) - 1).padStart(2, "0")
                    );
                  }
                }}
              />
            </IconeBotaoPage>
            {gerarBotoes(
              dadosTabela.pagina,
              dadosTabela.paginas,
              dadosTabela.maxBotoes,
              dadosTabela.mudarPagina
            )}
            <IconeBotaoPage
              disabled={pagination.state.page === dadosTabela.paginas - 1}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                color={tema.cores.actionActive}
                style={{ width: 24, height: 24 }}
                onClick={() => {
                  if (dadosTabela.paginas - 1 > pagination.state.page) {
                    pagination.fns.onSetPage(pagination.state.page + 1);
                    dadosTabela?.mudarPagina(dadosTabela.pagina + 1, body);
                    setValorInput((state) =>
                      String(Number(state) + 1).padStart(2, "0")
                    );
                  }
                }}
              />
            </IconeBotaoPage>
            <IconeBotaoPage
              disabled={pagination.state.page === dadosTabela.paginas - 1}
            >
              <IconsFirstAndLastPage
                className="material-symbols-rounded"
                onClick={() => {
                  if (pagination.state.page !== dadosTabela.paginas - 1) {
                    pagination.fns.onSetPage(dadosTabela.paginas - 1);
                    dadosTabela?.mudarPagina(dadosTabela.paginas - 1, body);
                    setValorInput((state) =>
                      String(Number(state) - 1).padStart(2, "0")
                    );
                  }
                }}
              >
                last_page
              </IconsFirstAndLastPage>
            </IconeBotaoPage>
          </ContainerPaginas>
        </ContainerPaginacao>
      ) : (
        ""
      )}
    </StyledContainerGlobal>
  );
}
