import { toast } from "react-toastify";
import { INTEGRADOR, PLATAFORMA } from "../enums/TipoEmpresa";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";

let sessao = {};
let lista = {};

const validarContrato = () => {
  try {
    const listaPermissoes = {
      CONTROLE_CASHBACK: "Fidelidade",
    };

    for (let i = 0; i < Object.keys(listaPermissoes).length; i++) {
      if (
        !sessao.contratoWaybeAtualDTO.permissoesWaybe.includes(
          Object.keys(listaPermissoes)[i]
        )
      ) {
        delete lista[listaPermissoes[Object.keys(listaPermissoes)[i]]];
      }
    }
  } catch (e) {
    console.error(e);
  }
};

const contrato = () => {
  if (!lista["Contrato"]) {
    return;
  }

  let listaOld = lista;
  try {
    var perfil = sessao.usuarioLogadoDTO.usuarioPerfilEmpresaDTO;
    var isMaster = perfil?.tipoPerfilUsuario === "MASTER";

    var isPossuiPermissaoPlanos =
      perfil?.listaPrivilegios?.PLAT_CONTRATOS !== undefined || isMaster;
    var isPossuiPermissaoExtras =
      perfil?.listaPrivilegios?.PLAT_CONTRATOS_EXTRAS !== undefined || isMaster;

    if (
      sessao.empresaDTO.tipoEmpresa !== INTEGRADOR &&
      sessao.empresaDTO.tipoEmpresa !== PLATAFORMA
    ) {
      delete lista["Contrato"]?.filhos["Controle de Contratos"];
    }
    //Verificar se a empresa é integrador e se o usuario possui permissão para acessar menu
    if (
      sessao.empresaDTO.tipoEmpresa !== INTEGRADOR ||
      !isPossuiPermissaoPlanos
    ) {
      delete lista["Contrato"]?.filhos["Waychef"]["Planos"];
      delete lista["Contrato"]?.filhos["Waymenu"]["Planos"];
    }

    if (
      sessao.empresaDTO.tipoEmpresa !== INTEGRADOR ||
      !isPossuiPermissaoExtras
    ) {
      delete lista["Contrato"]?.filhos["Extras"];
    }

    // /* PLATAFORMA */

    //Verificar se a empresa é plataforma e se o usuario possui permissão para acessar menu
    try {
      if (
        sessao.empresaDTO.tipoEmpresa !== PLATAFORMA ||
        !isPossuiPermissaoPlanos
      ) {
        if (lista["Contrato"]?.filhos["Waychef"]) {
          delete lista["Contrato"]?.filhos["Waychef"]["Planos padrão"];
        }
        if (lista["Contrato"]?.filhos["Waymenu"]) {
          delete lista["Contrato"]?.filhos["Waymenu"]["Planos padrão"];
        }
      }
    } catch (error) {
      console.error(error);
    }

    if (
      sessao.empresaDTO.tipoEmpresa !== PLATAFORMA ||
      !isPossuiPermissaoExtras
    ) {
      delete lista["Contrato"]?.filhos["Extras padrão"];
    }

    //Verificar se não sobrou nenhum filho do waychef e tirar o menu
    if (Object.keys(lista["Contrato"]?.filhos["Waychef"]).length === 0) {
      delete lista["Contrato"]?.filhos["Waychef"];
    }

    //Verificar se não sobrou nenhum filho do waymenu e tirar o menu
    if (Object.keys(lista["Contrato"]?.filhos["Waymenu"]).length === 0) {
      delete lista["Contrato"]?.filhos["Waymenu"];
    }

    //Verificar se o contrato não possui nenhum filho e retirar do menu
    if (Object.keys(lista["Contrato"]?.filhos).length === 0) {
      delete lista["Contrato"];
    }

    if (
      sessao.empresaDTO.tipoEmpresa === PLATAFORMA ||
      sessao.empresaDTO.tipoEmpresa === INTEGRADOR ||
      !isMaster
    ) {
      delete lista["Gerenciamento assinatura"];
    }
  } catch (error) {
    console.error(error);
    if (listaOld["Contrato"]) {
      delete listaOld["Contrato"];
      lista = listaOld;
    }
    if (listaOld["Gerenciamento assinatura"]) {
      delete listaOld["Gerenciamento assinatura"];
      lista = listaOld;
    }
  }

  //todoRafa remover
  // delete lista["Waymenu"];
  // delete lista["Relatórios"];
  // delete lista["Cadastros"];
  // delete lista["Operacional"];
  // delete lista["Serviços"];
  // delete lista["Segurança"];
  // delete lista["Consultas"];
  // delete lista["Dashboard"];
};

const fidelidade = () => {
  try {
    //Verificar se não tem contrato, já retornar
    if (
      !sessao.contratoWaybeAtualDTO.permissoesWaybe.includes(
        "CONTROLE_CASHBACK"
      )
    ) {
      return;
    }

    //Verificar se a empresa é FILIAL e retirar fidelidade
    if (sessao.empresaDTO.tipoEmpresa == "FILIAL") {
      delete lista["Fidelidade"];
    }

    //Verificar se a empresa é FRANQUEADO e retirar permissoes
    if (sessao.empresaDTO.tipoEmpresa == "FRANQUEADO") {
      delete lista.Fidelidade.filhos.Cashback.Cadastros;
      delete lista.Fidelidade.filhos.Cashback.Configurações;
    }

    //Verificar se o usuario não possui permissão 3 para poder alterar itens e remover o configurações do menu
    if (
      sessao.usuarioLogadoDTO?.usuarioPerfilEmpresaDTO?.listaPrivilegios
        ?.PARAM_CAD_CASHBACK != "ALTERAR" &&
      sessao.usuarioLogadoDTO?.usuarioPerfilEmpresaDTO?.listaPrivilegios
        ?.PARAM_CAD_CASHBACK != "EXCLUIR" &&
      sessao.usuarioLogadoDTO.usuarioPerfilEmpresaDTO.tipoPerfilUsuario !=
        "MASTER"
    ) {
      delete lista.Fidelidade.filhos.Cashback.Configurações;
    }
  } catch (e) {
    console.error(e);
  }
};

const relatorios = () => {
  //Verificar se o usuario é master e deixar visualizar menu de relatórios
  if (
    sessao.usuarioLogadoDTO.usuarioPerfilEmpresaDTO.tipoPerfilUsuario ===
    "MASTER"
  ) {
    return;
  }

  //Verificar se o usuário pode consultar o menu de Análise e Relatórios > Produtos Vendidos
  if (
    sessao.usuarioLogadoDTO.usuarioPerfilEmpresaDTO.listaPrivilegios
      .REL_FAT_PROD_VENDIDOS == undefined
  ) {
    delete lista["Relatórios"].filhos["Produtos Vendidos"];
  }

  //Verificar se o usuário pode consultar o menu de Análise e Relatórios
  if (
    !lista["Relatórios"].filhos ||
    Object.keys(lista["Relatórios"]?.filhos).length === 0
  ) {
    delete lista["Relatórios"];
  }
};

const wayservice = () => {
  //Verificar o contrato
  if (!sessao.contratoWaychefAtualDTO.listaItens.includes("WAYSERVICE")) {
    delete lista["Cadastros"]?.filhos["Cardápios"];
  }

  //Verificar se o usuário pode consultar o menu de cadastro de cardapio
  if (
    sessao.usuarioLogadoDTO.usuarioPerfilEmpresaDTO.tipoPerfilUsuario ===
    "MASTER"
  ) {
    return;
  }

  if (
    sessao.usuarioLogadoDTO.usuarioPerfilEmpresaDTO.listaPrivilegios
      .PROD_CAD_CARDAPIOS == undefined
  ) {
    delete lista["Cadastros"]?.filhos["Cardápios"];
  }
};

export default function verificarPermissaoContrato(listaMenus) {
  try {
    //Verificar se o usuario é do tipo master
    sessao = JSON.parse(localStorage.getItem("sessao"));
    lista = listaMenus;

    //todoRafa ajustar
    // Tratativa para retirar todos os menus quando a empresa for plataforma e integrador
    // Menos Dashboard e Contrato
    if (
      sessao.empresaDTO.tipoEmpresa === "PLATAFORMA" ||
      sessao.empresaDTO.tipoEmpresa === "INTEGRADOR"
    ) {
      var listaReduzida = {};
      listaReduzida["Dashboard"] = lista["Dashboard"];
      listaReduzida["Contrato"] = lista["Contrato"];

      if (sessao.empresaDTO.tipoEmpresa === "PLATAFORMA") {
        var relatoriosPlataforma = lista["Relatórios Plataforma"];
        relatoriosPlataforma.ativo = true;
        listaReduzida["Relatórios"] = relatoriosPlataforma;
      }

      lista = listaReduzida;
      // validarContrato();
      contrato();

      return lista;
    }

    //Lista de validações de cada menu separadamente
    let menusValidar = [
      // validarContrato(),
      contrato(),
      fidelidade(),
      relatorios(),
      wayservice(),
    ];

    for (let i = 0; i < menusValidar.length; i++) {
      menusValidar[i];
    }

    //Tratativa para retirar menus com nenhu filho, isso pode acontecer caso a permissão remova
    for (let index = 0; index < Object.keys(lista)?.length; index++) {
      if (!Object.keys(lista[Object.keys(lista)[index]]?.filhos)?.length) {
        delete lista[Object.keys(lista)[index]];
      }
    }

    return lista;
  } catch (error) {
    console.error(error);
  }
}

export const verificarPermissaoAcessoPlataformaContrato = () => {
  try {
    //Verificar se o usuario é do tipo master
    const sessao = JSON.parse(localStorage.getItem("sessao"));
    if (
      !(
        sessao.usuarioLogadoDTO.usuarioPerfilEmpresaDTO.tipoPerfilUsuario ==
        "MASTER"
      )
    ) {
      if (
        sessao.empresaDTO.tipoEmpresa !== "PLATAFORMA" ||
        (sessao.empresaDTO.tipoEmpresa === "PLATAFORMA" &&
          sessao.usuarioLogadoDTO.usuarioPerfilEmpresaDTO?.listaPrivilegios
            ?.PLAT_CONTRATOS == undefined)
      ) {
        toast.error("Seu usuário não possui permissão para acessar pagina!");
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);
      }
    }
  } catch (error) {}
};

export const verificarPermissaoAcessoPlataformaContratoExtra = () => {
  try {
    //Verificar se o usuario é do tipo master
    const sessao = JSON.parse(localStorage.getItem("sessao"));
    if (
      !(
        sessao.usuarioLogadoDTO.usuarioPerfilEmpresaDTO.tipoPerfilUsuario ==
        "MASTER"
      )
    ) {
      if (
        sessao.empresaDTO.tipoEmpresa !== "PLATAFORMA" ||
        (sessao.empresaDTO.tipoEmpresa === "PLATAFORMA" &&
          sessao.usuarioLogadoDTO.usuarioPerfilEmpresaDTO?.listaPrivilegios
            ?.PLAT_CONTRATOS_EXTRAS == undefined)
      ) {
        toast.error("Seu usuário não possui permissão para acessar pagina!");
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      }
    }
  } catch (error) {}
};

export const verificarPermissaoAcessoIntegradorContratoExtra = () => {
  try {
    //Verificar se o usuario é do tipo master
    const sessao = JSON.parse(localStorage.getItem("sessao"));
    if (
      !(
        sessao.usuarioLogadoDTO.usuarioPerfilEmpresaDTO.tipoPerfilUsuario ==
        "MASTER"
      )
    ) {
      if (
        sessao.empresaDTO.tipoEmpresa != "INTEGRADOR" ||
        (sessao.empresaDTO.tipoEmpresa === "INTEGRADOR" &&
          sessao.usuarioLogadoDTO.usuarioPerfilEmpresaDTO?.listaPrivilegios
            ?.PLAT_CONTRATOS_EXTRAS != undefined)
      ) {
        toast.error("Seu usuário não possui permissão para acessar pagina!");
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      }
    }
  } catch (error) {
    console.error(error);
  }
};
