import { useTheme } from "@emotion/react";

import { Container, CarregandoAnimacao, Logo } from "./styles";
import WaybeLogo from "../../assets/images/loading-logo.svg";

export default function Carregando({
  larguraSpinner = 130,
  larguraImagem = 70,
  margemDiminuirImagem = 97,
  adaptavel = false, ...props
}) {
  const tema = useTheme();
  return (
    <div
      style={
        adaptavel
          ? {
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }
          : { minHeight: "868px" }
      }
      {...props}
    >
      <Container>
        <CarregandoAnimacao larguraSpinner={larguraSpinner} />
        <Logo src={WaybeLogo} larguraImagem={larguraImagem} margemDiminuirImagem={margemDiminuirImagem} />
      </Container>
    </div>
  );
}
