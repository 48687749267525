import styled from "@emotion/styled";
import {
  Autocomplete,
  Chip,
  FormControl,
  InputLabel,
  TextField,
  Tooltip,
} from "@mui/material/";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { NumericFormat, NumberFormatBase } from "react-number-format";
import { DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import { MuiColorInput } from "mui-color-input";

export const CampoDeTextoBase = styled(TextField)`
  width: 100%;
  height: 56px;

  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    padding-left: ${(props) => (props.value != "" ? `16px;` : "16px;")};
    ${(props) => props.value && `padding-top: 7px;`};
  }

  input {
    padding-left: 2px;
    padding-top: 19px;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  div {
    height: 56px;
  }

  .Mui-disabled {
    cursor: ${({ typeNumber }) => typeNumber === false && "not-allowed"};
    opacity: ${({ typeNumber }) => (typeNumber === true ? 1 : 1)};
    color: ${({ theme }) => theme.cores.corInputDisabled};
    -webkit-text-fill-color: ${({ theme }) => theme.cores.corInputDisabled};
  }

  ${(props) => !props.theme.temaDarkAtivo && `
      opacity: 1;
      -webkit-text-fill-color: rgba(0, 0, 0, 0.8)!important;
      color: rgba(0, 0, 0, 0.7)!important;
      cursor: not-allowed!important;
    `};

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
    props.ocultarSetas &&
    `
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
  `}

  .MuiFilledInput-input {
    &:has(input:focus) label {
      padding-top: 0px;
      padding-left: 56px;
    }
  }
`;
export const CampoDeInputNumericoAlternativoBase = styled(TextField)`
  width: 100%;
  height: 56px;

  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    padding-left: ${(props) =>
    props.value != "" || props.value != null ? `16px;` : "12px;"};
    padding-top: ${(props) =>
    props.value != "" && props.value != null && `8px;`};
  }

  input {
    padding-left: 2px;
    padding-top: 19px;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  div {
    height: 56px;
  }

  .Mui-disabled {
    cursor: ${({ typeNumber }) => typeNumber === false && "not-allowed"};
    opacity: ${({ typeNumber }) => (typeNumber === true ? 1 : 1)};
    color: ${({theme}) => theme.cores.corInputDisabled};
    -webkit-text-fill-color: ${({theme}) => theme.cores.corInputDisabled};


  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
    props.ocultarSetas &&
    `
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
  `}

  .MuiFilledInput-input {
    &:has(input:focus) label {
      padding-top: 0px;
      padding-left: 56px;
    }
  }
`;

export const CampoDeTextoBaseSelecionarCliente = styled(TextField)`
 width: 100%;
  height: 56px;

  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    padding-left: ${(props) =>
    props.value != "" || props.value != null ? `16px;` : "12px;"};
    padding-top:${(props) =>
    props.value != "" && props.value != null && `8px;`};;
  }

  input {
    padding-left: 2px;
    padding-top: 19px;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  .Mui-Disabled {
    ${(props) =>
      !props.theme.temaDarkAtivo &&
      `
      -webkit-text-fill-color: rgba(0, 0, 0, 0.8)!important;
      color: rgba(0, 0, 0, 0.7)!important;
      cursor: not-allowed!important;
    `};
  }

  div {
    height: 56px;
  }

`;

export const FormularioCustomizado = styled(FormControl)`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    padding-left: ${(props) => (props.value != "" ? `16px;` : "16px;")};
    ${(props) => props.value != "" && `padding-top: 7px;`};
  }

  input {
    padding-left: 2px;
    padding-top: 19px;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  .Mui-disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const InputCustomizado = styled(InputLabel)``;

export const FormularioCustomizadoSelecao = styled(FormControl)`
  width: 100%;

  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    padding-left: ${(props) =>
    props.value != "" || props.value != null ? `12px;` : "16px;"};
    padding-top: 6px;
  }

  input {
    padding-left: 12px;
    padding-top: 19px;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  .MuiSvgIcon-root {
    color: ${(props) => (props.cor != null ? `rgb(${props.cor})` : null)};
    margin-right: 12px;
  }

  height: 56px;

  div {
    height: 56px;
    padding-top: 12px;
  }

  .Mui-disabled {
    cursor: not-allowed;
    opacity: 0.99;
  }
`;

export const CampoDeDataSemHoraBase = styled(DatePicker)`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .MuiInputAdornment-root {
    padding-right: 12px;
  }

  label {
    padding-left: ${(props) => (props.value != "" ? `16px;` : "12px;")};
    ${(props) =>
      props.value != "" && props.value != null && `padding-top: 5px;`}
  }

  input {
    padding-left: 2px;
    padding-top: 19px;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  height: 56px;

  div {
    height: 56px;
  }
`;

export const CampoDeDataBase = styled(DateTimePicker)`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .MuiInputAdornment-root {
    padding-right: 12px;
  }

  label {
    padding-left: ${(props) => (props.value != "" ? `16px;` : "12px;")};
    ${(props) =>
      props.value != "" && props.value != null && `padding-top: 5px;`}
  }

  input {
    padding-left: 2px;
    padding-top: 19px;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  height: 56px;

  div {
    height: 56px;
  }
`;

export const CampoDeTextoBaseAutoComplete = styled(TextField)`
  width: 100%;

  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    padding-left: ${(props) =>
      props.value != "" || props.value != null ? `16px;` : "12px;"};
    padding-top:${(props) =>
      props.value != "" && props.value != null && `8px;`};;
  }

  input {
    padding-left: 12px;
    padding-top: 19px;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  .MuiInputBase-root {
    min-height: 56px;
    padding-top: 15px;
    height: auto;
  }

  .MuiChip-root {
    background: transparent;
    border: solid 0.5px ${({ theme }) => theme.cores.actionActive};
    span {
      padding: 2px 13px;
      color: ${({ theme }) => theme.cores.actionActive};
    }
    svg {
      font-size: 18px;
    }
    path {
      fill: ${({ theme }) =>
        String(theme.cores.actionActive).replace("1)", "0.4)")};
    }
  }

  .MuiAutocomplete-endAdornment {
    padding-right: 12px;
  }

  @media (max-width: 768px) {
    .MuiInputBase-root {
      padding-top: 25px;
      padding-bottom: 5px;
    }
    .MuiInputBase-root > div {
      margin-top: 0px;
    }
  }

  .Mui-disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const ContainerData = styled(FormularioCustomizado)`
  margin: 0;
  padding: 0;
  background-color: transparent;
`;

export const FormularioCustomizadoMultiSelect = styled(
  FormularioCustomizadoSelecao
)`
  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.05)")
      : "transparent"};

  label {
    margin-top: -16px;
  }

  div {
    height: min-content;
    padding-top: 0px;
    margin-top: 8px;
  }

  .MuiFormControl-root {
    margin-top: -4px;
    height: min-content;
  }

  .Mui-Disabled {
    ${(props) =>
      !props.theme.temaDarkAtivo &&
      `
      -webkit-text-fill-color: rgba(0, 0, 0, 0.9)!important;
      color: rgba(0, 0, 0, 0.7)!important;
      cursor: not-allowed!important;
    `};
  }
`;

export const TextFieldMonetario = styled(TextField)`
  //margin-top: 20px;
  width: 100%;

  .MuiInput-input {
    /* padding-left: 12px; */
  }

  .MuiInput-root {
    font-size: 20px;
    font-family: "Source Sans Pro", sans-serif;

    color: ${(props) => props.theme.adaptativo("neutral40", "neutral100")};
  }

  .MuiTypography-root {
    font-size: 20px;
    font-family: "Source Sans Pro", sans-serif;

    color: ${(props) => props.theme.adaptativo("neutral40", "neutral100")};
  }

  .MuiInputBase-root {
    padding: 5px 0;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const TextFieldMonetarioPadronizado = styled(TextField)`
  width: 100%;
  height: 56px;

  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    padding-left: ${(props) =>
    props.value != "" || props.value != null ? `16px;` : "12px;"};
    padding-top: ${(props) =>
    props.value != "" && props.value != null && `8px;`};
  }

  input {
    padding-left: 2px;
    padding-top: 19px;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  div {
    height: 56px;
  }

  .Mui-disabled {
    cursor: ${({ typeNumber }) => typeNumber === false && "not-allowed"};
    opacity: ${({ typeNumber }) => (typeNumber === true ? 1 : 0.7)};
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
    props.ocultarSetas &&
    `
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
  `}

  .MuiFilledInput-input {
    &:has(input:focus) label {
      padding-top: 0px;
      padding-left: 56px;
    }
  }
`;

export const AutocompleteCustomizado = styled(Autocomplete)`
  width: 100%;
  min-height: 56px;
  height: auto;

  .MuiButtonBase-root {
    height: 25px;
    margin-top: 14px;
  }

  .Mui-disabled {
    .MuiChip-root {
      opacity: 1;
    }
    ${(props) =>
      !props.theme.temaDarkAtivo &&
      `
      -webkit-text-fill-color: rgba(0, 0, 0, 0.8)!important;
      color: rgba(0, 0, 0, 0.7)!important;
      cursor: not-allowed!important;
    `};
  }
`;

export const NumericFormatCustomizado = styled.div`
  width: 220px;
  display: flex;
  position: relative;

  .botao {
    margin-top: 3px;
    cursor: pointer;
    font-size: 1rem;
    height: 1rem;
    opacity: 0.5;
    color: ${({ theme }) => theme.cores.textoSimples};
  }

  .botao:hover {
    opacity: 1;
  }

  .sub {
    margin-left: 5px;
    position: absolute;
    left: 0;
    top: 45%;

    @media (max-width: 768px) {
      top: 30%;
    }
  }
  .add {
    margin-right: 5px;
    position: absolute;
    right: 0;
    top: 44%;

    @media (max-width: 768px) {
      top: 28%;
    }
  }

  .MuiInput-input {
    text-align: center;
    font-size: 20px !important;
    padding: 0px 28px;
    padding-top: 15px;

    @media (max-width: 768px) {
      margin-bottom: -1px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  .Mui-disabled {
    ${(props) =>
      !props.theme.temaDarkAtivo &&
      `
      -webkit-text-fill-color: rgba(0, 0, 0, 0.8)!important;
      color: rgba(0, 0, 0, 0.7)!important;
      cursor: not-allowed!important;
    `};
  }
`;

export const NumericFormatInput = styled(NumericFormat)`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .MuiInputAdornment-root {
    padding-right: 12px;
  }

  label {
    padding-left: ${(props) => (props.value != "" ? `16px;` : "12px;")};
    ${(props) =>
      props.value != "" && props.value != null && `padding-top: 5px;`}
  }

  input {
    padding-left: 12px;
    padding-top: 19px;
    padding-bottom: 17px;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  height: 56px;

  .Mui-disabled {
    ${(props) =>
      !props.theme.temaDarkAtivo &&
      `
      -webkit-text-fill-color: rgba(0, 0, 0, 0.8)!important;
      color: rgba(0, 0, 0, 0.7)!important;
      cursor: not-allowed!important;
    `};
  }

  div {
    height: 56px;
  }
`;

export const NumberFormatBaseInput = styled(NumberFormatBase)`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    padding-left: ${(props) => (props.value != "" ? `16px;` : "12px;")};
    ${(props) =>
      props.value != "" && props.value != null && `padding-top: 5px;`}
  }

  input {
    padding-left: 12px;
    padding-top: 19px;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  .Mui-Disabled {
    ${(props) =>
      !props.theme.temaDarkAtivo &&
      `
      -webkit-text-fill-color: rgba(0, 0, 0, 0.8)!important;
      color: rgba(0, 0, 0, 0.7)!important;
      cursor: not-allowed!important;
    `};
  }

  height: 56px;

  div {
    height: 56px;
  }
`;
export const CampoDeHoraBase = styled(TimePicker)`
  margin-top: 8px;
  width: 100%;
  /* margin-right: 32px; */

  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  @media (max-width: 768px) {
    width: 100%;
    /* margin-bottom: 30px; */
    margin-right: 0px;
  }

  .Mui-disabled {
    ${(props) =>
      !props.theme.temaDarkAtivo &&
      `
      -webkit-text-fill-color: rgba(0, 0, 0, 0.8)!important;
      color: rgba(0, 0, 0, 0.7)!important;
      cursor: not-allowed!important;
    `};
  }
`;

export const CampoDeTextoCustom = styled(TextField)`
  width: 100%;
  height: 45px;

  input {
    padding-left: 2px;
    font-family: "Comfortaa", sans-serif;
  }

  div {
    height: 56px;
  }

  .MuiInputBase-root:before {
    ${(props) => props.disabled && `border-bottom: 0px`}
  }

  .MuiInputBase-root {
    height: 29px !important;
  }

  .Mui-disabled {
    ${(props) =>
      !props.theme.temaDarkAtivo &&
      `
      -webkit-text-fill-color: rgba(0, 0, 0, 0.8)!important;
      color: rgba(0, 0, 0, 0.7)!important;
      cursor: not-allowed!important;
    `};
  }
`;

export const InputPorcentagemWayservice = styled(NumberFormatBase)`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    padding-left: ${(props) => (props.value != "" ? `16px;` : "12px;")};
    ${(props) => props.value != "" && `padding-top: 7px;`}
  }

  input {
    padding-left: 12px;
    padding-top: 19px;
    font-size: 24px !important;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  height: 56px;

  div {
    height: 56px;
  }
`;

export const CampoCorBase = styled(MuiColorInput)`
  width: 100%;
  height: 56px;

  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    padding-left: 60px;
    padding-top: 7px;
  }

  input {
    padding-left: 2px;
    padding-top: 19px;
  }

  div {
    height: 56px;
  }

  .Mui-disabled {
    cursor: ${({ typeNumber }) => typeNumber === false && "not-allowed"};
    opacity: ${({ typeNumber }) => (typeNumber === true ? 1 : 0.7)};
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const CustomTooltip = styled(Tooltip)`
  background: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 99px;
  color: #FFF;
`
