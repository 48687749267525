import { useTheme } from "@emotion/react";
import { useState, useContext, useEffect } from "react";
import { NavegacaoContext } from "../../contexts/navegacao";
import { ReactComponent as IconeExpandir } from "../../assets/icons/expand_more.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { ItemAdicionarDashboard, ItemMenu } from "./styles";

import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";

const ContainerNaoSelecionavel = styled.div`
  user-select: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icone-menu {
    color: ${(props) => props.theme.cores.textoSimples};
    fill: ${(props) => props.theme.cores.textoSimples};
    ${(props) =>
    props.aberto && `animation: trocarCor 0.3s ease-in-out forwards`};

    &-direita {
      transform: rotate(-90deg);
    }

    &-esquerda {
      transform: rotate(90deg);
    }
  }

  .icone-menu-painel-transicao-inverter {
    ${(props) =>
    props.$aberto && `animation: girarIcone 0.3s ease-in-out forwards`};
  }

  @keyframes girarIcone {
    0% {
      transform: rotate(0deg);
      fill: ${(props) => props.theme.cores.textoSimples};
    }
    100% {
      transform: rotate(180deg);
      fill: ${(props) => props.theme.cores.brand40Default};
    }
  }

  @keyframes trocarCor {
    0% {
      color: ${(props) => props.theme.cores.textoSimples};
      fill: ${(props) => props.theme.cores.textoSimples};
    }
    100% {
      color: ${(props) => props.theme.cores.brand40Default};
      fill: ${(props) => props.theme.cores.brand40Default};
    }
  }
`;

const ComponentePaginado = ({
  Filho,
  navegacaoInicial,
  Separador = () => <></>,
  mobile = false,
}) => {
  const theme = useTheme();
  const [navegacaoPadrao] = useContext(NavegacaoContext);
  const [navegacao, setNavegacao] = useState(navegacaoInicial);
  const location = useLocation();

  useEffect(() => {
    setNavegacao(navegacaoInicial);
  }, [navegacaoInicial]);

  const funcoesBotaoNavegacao = {
    "object": (filho, chave, nav) => {
      if (!chave.includes("voltar:")) {
        filho[`voltar:${chave}`] = nav;
        //Remover itens invalidos
        try {
          delete filho.icone;
          Object.keys(filho.filhos).map((item) => {
            filho[item] = filho.filhos[item];
          });
          delete filho.filhos;
        } catch (error) { }
      }
      setNavegacao(filho);
    },
    "function": (filho) => {
      filho();
    },
    "string": (filho) => { },
  };

  const intercalar = (array, Separador) => {
    let resultado = [];
    for (let i = 0; i < array.length; i++) {
      resultado.push(array[i]);
      if (i !== array.length - 1) {
        resultado.push(<Separador key={i} />);
      }
    }
    return resultado;
  };

  const tipoAdicionarDashboard = (texto) => {
    try {
      if (texto == "ativo") {
        return <></>;
      }

      if (texto == "Novo") {
        return (
          <ItemAdicionarDashboard>
            Novo
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ color: theme.cores.neutral100 }}
              fontSize={19}
            />
          </ItemAdicionarDashboard>
        );
      }

      return <ItemMenu>{texto}</ItemMenu>;
    } catch (e) { }
  };

  const mapearBotoesNavegacao = (nav) => {
    if (nav == null) {
      return (<></>)
    }
    let botoes = Object.keys(nav).map((item) => {
      //Verificar se o item é o objeto ativo e não mostrar
      let voltar = item.includes("voltar:");
      let texto = item.split(":")[0 + voltar];

      if (texto == "ativo") {
        return;
      }

      return (
        <div
          style={{
            width: "100%",
          }}
          key={item}
          onClick={() =>
            funcoesBotaoNavegacao[typeof nav[item]](nav[item], item, nav)
          }
        >
          <Filho texto={texto}>
            {voltar ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "16px",
                  height: "16px",
                  marginRight: "8px",
                }}
              >
                <IconeExpandir className="icone-menu icone-menu-esquerda" />
              </div>
            ) : (
              <></>
            )}

            {tipoAdicionarDashboard(texto)}

            {typeof nav[item] === "object" && !voltar ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "16px",
                  height: "16px",
                }}
              >
                <IconeExpandir className="icone-menu icone-menu-direita" />
              </div>
            ) : (
              <></>
            )}
          </Filho>
        </div>
      );
    });

    return intercalar(
      botoes.sort((a, b) => {
        if (a.key.includes("voltar") && !b.key.includes("voltar")) {
          return -1;
        } else if (!a.key.includes("voltar") && b.key.includes("voltar")) {
          return 1;
        } else {
          return 0;
        }
      }),
      Separador
    );
  };
  return (
    <ContainerNaoSelecionavel>
      {mapearBotoesNavegacao(navegacao)}
    </ContainerNaoSelecionavel>
  );
};

export default ComponentePaginado;
